import { ResourceLanguage } from 'i18next'
import {
	AccountRoutes,
	AddressErrors,
	AddressTranslations,
	FilterColor,
	DeliveryStatusTranslations,
	MenuCategoryTranslations,
	Translations,
	Password,
	Cart,
	Payment,
	SignUp,
	SignIn,
	Product,
	FooterPage,
	Delivery,
	ReturnOrder,
	PasswordRecover,
	OrderStatusTranslation,
	ErrorTranslations,
} from './translations.enum'

const translations: Record<
	| Translations
	| MenuCategoryTranslations
	| AccountRoutes
	| FilterColor
	| AddressTranslations
	| AddressErrors
	| Password
	| Cart
	| Payment
	| SignUp
	| SignIn
	| Product
	| FooterPage
	| Delivery
	| ReturnOrder
	| DeliveryStatusTranslations
	| PasswordRecover
	| OrderStatusTranslation
	| ErrorTranslations,
	string
> = {
	SEARCH: 'Pesquisa',
	COOKIE_POLICY: 'Política de cookies',
	TERMS_CONDITIONS: 'Termos e Condições',
	PRIVACY_POLICY: 'Política de privacidade',
	FAQS: 'FAQs',
	SPANISH: 'Espanhol',
	ENGLISH: 'Inglês',
	ITALIAN: 'Italiano',
	PORTUGUESE: 'Português',
	SHOPPING_BAG: 'Saco de compras',
	WISHLIST: 'Lista de desejos',
	MYFINAEJERIQUE: 'A minha Fina Ejerique',
	CUSTOMER_SERVICE: 'Serviço de Apoio ao Cliente',
	NEED_HELP_ORDER: 'Precisa de ajuda com o seu pedido?',
	COLOR: 'Cor',
	SIZE: 'Tamanho',
	SIZE_CHART: 'SIZE_CHART',
	REGION: 'Região',
	LANGUAGE: 'Idioma',
	SHIPPING_TO: 'Envio para',
	SHIPPING_LOCATION: 'Portugal',
	BABY: 'Bebé',
	NEW_BORN: 'Recém-nascido',
	CHILDREN: 'Crianças',
	SIGN_IN: 'Iniciar sessão',
	SIGN_OUT: 'Fechar sessão',
	SIGN_UP: 'Registar',
	LOADING: 'A carregar',
	DESCRIPTION: 'Descrição',
	DETAILS: 'Detalhes',
	DELIVERY: 'Envio',
	CONTACT_US: 'Contacte-nos',
	SIMILAR_PRODUCTS: 'Produtos similares',
	YOU_MAY_ALSO_LIKE: 'Também pode gostar',
	ADD_TO_CART: 'Adicionar ao carrinho',
	CLEAR_FILTERS: 'Limpar filtros',
	FILTER: 'Filtrar',
	APPLY: 'Aplicar',
	ACCOUNT_OVERVIEW: 'ACCOUNT_OVERVIEW',
	ORDER_HISTORY: 'ORDER_HISTORY',
	ADDRESS_BOOK: 'ADDRESS_BOOK',
	ADDRESS_EDIT: 'ADDRESS_EDIT',
	ADDRESS_NEW: 'ADDRESS_NEW',
	CHANGE_PASSWORD: 'CHANGE_PASSWORD',
	CONTINUE_TO_PAYMENT: 'CONTINUE_TO_PAYMENT',
	ORDER_VIEW: 'ORDER_VIEW',
	RED_PALETTE: 'Reds',
	PINK_PALETTE: 'Pinks',
	PURPLE_PALETTE: 'Purple',
	BLUE_PALETTE: 'Blues',
	GREEN_PALETTE: 'Greens',
	YELLOW_PALETTE: 'Yellows',
	ORANGE_PALETTE: 'Oranges',
	BROWN_PALETTE: 'Browns',
	GRAY_PALETTE: 'Grays',
	WHITE_PALETTE: 'Whites',
	BLACK_PALETTE: 'Blacks',
	MY_INFO: 'MY_INFO',
	ORDER_RETURN: 'ORDER_RETURN',
	RETURNS: 'RETURNS',
	RETURN_VIEW: 'RETURN_VIEW',
	ORDER_HISTORY_DESCRIPTION: 'ORDER_HISTORY_DESCRIPTION',
	ADDRESS_BOOK_DESCRIPTION: 'ADDRESS_BOOK_DESCRIPTION',
	PASSWORD_CHANGE_DESCRIPTION: 'PASSWORD_CHANGE_DESCRIPTION',
	RETURN_DESCRIPTION: 'RETURN_DESCRIPTION',
	ACCOUNT_OVERVIEW_DESCRIPTION: 'ACCOUNT_OVERVIEW_DESCRIPTION',
	ADDRESS: 'ADDRESS',
	ADDRESS_NO_ADDRESSES: 'ADDRESS_NO_ADDRESSES',
	ADDRESS_FIRST_NAME: 'ADDRESS_FIRST_NAME',
	ADDRESS_LAST_NAME: 'ADDRESS_LAST_NAME',
	ADDRESS_COUNTRY: 'ADDRESS_COUNTRY',
	ADDRESS_CITY: 'ADDRESS_CITY',
	ADDRESS_PROVINCE: 'ADDRESS_PROVINCE',
	ADDRESS_POSTAL_CODE: 'ADDRESS_POSTAL_CODE',
	ADDRESS_STREET_AND_NUMBER: 'ADDRESS_STREET_AND_NUMBER',
	ADDRESS_ADDITIONAL_DATA: 'ADDRESS_ADDITIONAL_DATA',
	ADDRESS_PHONE_NUMBER: 'ADDRESS_PHONE_NUMBER',
	ADDRESSES: 'ADDRESSES',
	ADD_ADDRESS: 'ADD_ADDRESS',
	EDIT_ADDRESS: 'EDIT_ADDRESS',
	DELIVERY_ADDRESS: 'DELIVERY_ADDRESS',
	ADDRESS_NAME: 'ADDRESS_NAME',
	SAVE_ADDRESS: 'SAVE_ADDRESS',
	EDIT: 'EDIT',
	DELETE: 'DELETE',
	CONTINUE_SHOPPING: 'CONTINUE_SHOPPING',
	CHECK_OUT: 'CHECK_OUT',
	SUBTOTAL: 'SUBTOTAL',
	TOTAL: 'TOTAL',
	SHIPPING: 'SHIPPING',
	SELECT_ADDRESS: 'SELECT_ADDRESS',
	UNITS: 'UNITS',
	ORDER_SUMMARY: 'ORDER_SUMMARY',
	ALL_FIELDS_REQUIRED: 'ALL_FIELDS_REQUIRED',
	MESSAGE_SENT_SUCCESSFULLY: 'MESSAGE_SENT_SUCCESSFULLY',
	AN_ERROR_OCCURRED: 'AN_ERROR_OCCURRED',
	SEND_MESSAGE: 'SEND_MESSAGE',
	MESSAGE: 'MESSAGE',
	CALL_US: 'CALL_US',
	EMAIL_US: 'EMAIL_US',
	CONTACT_US_DESCRIPTION: 'CONTACT_US_DESCRIPTION',
	NEWS_LETTER: 'NEWS_LETTER',
	NEWS_LETTER_LABEL: 'NEWS_LETTER_LABEL',
	NEWS_LETTER_SIGNUP: 'NEWS_LETTER_SIGNUP',
	NEWS_LETTER_BUTTON: 'NEWS_LETTER_BUTTON',
	ORDERING_AND_SHIPPING: 'ORDERING_AND_SHIPPING',
	THE_FE_STORY: 'THE_FE_STORY',
	CAREERS: 'CAREERS',
	CUSTOMER_CARE: 'CUSTOMER_CARE',
	ABOUT_FE: 'ABOUT_FE',
	FOLLOW_FE: 'FOLLOW_FE',
	VIEW_DETAILS: 'VIEW_DETAILS',
	ITEMS: 'ITEMS',
	ORDER_NUMBER: 'ORDER_NUMBER',
	REQUEST_RETURN: 'REQUEST_RETURN',
	ERROR_ADDRESS_NAME: 'Please select a name for the address',
	ERROR_ADDRESS_FIRST_NAME: 'Please add you name',
	ERROR_ADDRESS_LAST_NAME: 'Please add you surname',
	ERROR_ADDRESS_COUNTRY: 'Please select your country',
	ERROR_ADDRESS_CITY: 'Please select your city',
	ERROR_ADDRESS_PROVINCE: 'Please select your province',
	ERROR_ADDRESS_POSTAL_CODE: 'Please select your postal code',
	ERROR_ADDRESS_STREET_AND_NUMBER: 'Please select your address',
	ERROR_ADDRESS_PHONE_NUMBER: 'Please select a valid phone number',
	NO_ADDRESSES: 'NO_ADDRESSES',
	BACK: 'BACK',
	PERSONAL_DATA: 'PERSONAL_DATA',
	BIRTH_DATE: 'BIRTH_DATE',
	SELECT_SIZE: 'SELECT_SIZE',
	RETURN_ADDRESS: 'RETURN_ADDRESS',
	DRAFT: 'DRAFT',
	PENDING_PAYMENT: 'PENDING_PAYMENT',
	PAID: 'PAID',
	PAYMENT_FAILED: 'PAYMENT_FAILED',
	PENDING_REVIEW: 'PENDING_REVIEW',
	REVIEWED: 'REVIEWED',
	SENT_TO_SIMSS: 'SENT_TO_SIMSS',
	ERROR_SENDING_TO_SIMSS: 'ERROR_SENDING_TO_SIMSS',
	CARRIER_NOTIFIED: 'CARRIER_NOTIFIED',
	ERROR_NOTIFYING_CARRIER: 'ERROR_NOTIFYING_CARRIER',
	SHIPPED: 'SHIPPED',
	DELIVERED: 'DELIVERED',
	COMPLETED: 'COMPLETED',
	CANCELLED: 'CANCELLED',
	PASSWORD_CHANGE: 'PASSWORD_CHANGE',
	PASSWORD_CURRENT: 'PASSWORD_CURRENT',
	PASSWORD_NEW: 'PASSWORD_NEW',
	PASSWORD_CONFIRM: 'PASSWORD_CONFIRM',
	PASSWORD_DETAILS: 'PASSWORD_DETAILS',
	PASSWORD_ERROR_OLD: 'PASSWORD_ERROR_OLD',
	PASSWORD_ERROR_NEW: 'PASSWORD_ERROR_NEW',
	PASSWORD_ERROR_CONFIRM: 'PASSWORD_ERROR_CONFIRM',
	PASSWORD_ERROR_NOT_MATCH: 'PASSWORD_ERROR_NOT_MATCH',
	PASSWORD_ERROR_INCOMPLETE: 'PASSWORD_ERROR_INCOMPLETE',
	PASSWORD_ERROR_INCORRECT: 'PASSWORD_ERROR_INCORRECT',
	PASSWORD_ERROR_ERROR: 'PASSWORD_ERROR_ERROR',
	CART_BAG: 'Bag',
	CART_EMAIL: 'Email',
	CART_DELIVERY: 'Delivery',
	CART_PAYMENT: 'Payment',
	CHECK_EMAIL_COMMUNICATIONS: 'CHECK_EMAIL_COMMUNICATIONS',
	CHECK_TERMS_AND_CONDITIONS: 'CHECK_TERMS_AND_CONDITIONS',
	PAYMENT_CONFIRM_AND_PAY: 'CONFIRM_AND_PAY',
	PAYMENT_ERROR_TERMS_CONDITIONS: 'PAYMENT_ERROR_TERMS_CONDITIONS',
	PAYMENT_ERROR_PAYMENT_METHOD: 'PAYMENT_ERROR_PAYMENT_METHOD',
	PAYMENT_ERROR_CREATING_ORDER_ID: 'PAYMENT_ERROR_CREATING_ORDER_ID',
	PAYMENT_ERROR_PAYMENT_METHOD_NOT_FOUND:
		'PAYMENT_ERROR_PAYMENT_METHOD_NOT_FOUND',
	PAYMENT_ERROR_CREATING_ORDER_RETRY: 'PAYMENT_ERROR_CREATING_ORDER_RETRY',
	PAYMENT_CREDIT_CARD: 'PAYMENT_CREDIT_CARD',
	PAYMENT_CREDIT_CARD_DESCRIPTION: 'PAYMENT_CREDIT_CARD_DESCRIPTION',
	PAYMENT_BIZUM: 'PAYMENT_BIZUM',
	PAYMENT_BIZUM_DESCRIPTION: 'PAYMENT_BIZUM_DESCRIPTION',
	SIGNUP_CREATE_ACCOUNT: 'SIGNUP_CREATE_ACCOUNT',
	SIGNUP_EMAIL: 'SIGNUP_EMAIL',
	SIGNUP_FIRST_NAME: 'SIGNUP_FIRST_NAME',
	SIGNUP_LAST_NAME: 'SIGNUP_LAST_NAME',
	SIGNUP_BIRTHDAY: 'SIGNUP_BIRTHDAY',
	SIGNUP_COUNTRY: 'SIGNUP_COUNTRY',
	SIGNUP_PASSWORD: 'SIGNUP_PASSWORD',
	SIGNUP_CONFIRM_PASSWORD: 'SIGNUP_CONFIRM_PASSWORD',
	SIGNUP_ALREADY_EXISTS: 'SIGNUP_ALREADY_EXISTS',
	SIGNUP_SIGNIN: 'SIGNUP_SIGNIN',
	SIGNUP_ERROR_INVALID_EMAIL: 'SIGNUP_ERROR_INVALID_EMAIL',
	SIGNUP_ERROR_INVALID_PASSWORD: 'SIGNUP_ERROR_INVALID_PASSWORD',
	SIGNUP_ERROR_TERMS_CONDITIONS: 'SIGNUP_ERROR_TERMS_CONDITIONS',
	SIGNUP_ERROR_PASSWORD_NOT_MATCH: 'SIGNUP_ERROR_PASSWORD_NOT_MATCH',
	SIGNUP_ERROR_COUNTRY: 'SIGNUP_ERROR_COUNTRY',
	SIGNUP_ERROR_FIRST_NAME: 'SIGNUP_ERROR_FIRST_NAME',
	SIGNUP_ERROR_LAST_NAME: 'SIGNUP_ERROR_LAST_NAME',
	SIGNUP_ERROR_RETRY: 'SIGNUP_ERROR_RETRY',
	SIGNUP_ERROR_CHECK_FIELDS: 'SIGNUP_ERROR_CHECK_FIELDS',
	SIGNIN_CREATE_ACCOUNT: 'SIGNIN_CREATE_ACCOUNT',
	SIGNIN_EMAIL: 'SIGNIN_EMAIL',
	SIGNIN_PASSWORD: 'SIGNIN_PASSWORD',
	SIGNIN_PASSWORD_FORGOT: 'SIGNIN_PASSWORD_FORGOT',
	SIGNIN_ACCOUNT_NOT_EXISTS: 'SIGNIN_ACCOUNT_NOT_EXISTS',
	SIGNIN_SIGNUP: 'SIGNIN_SIGNUP',
	SIGNIN_ERROR_INVALID_EMAIL: 'SIGNIN_ERROR_INVALID_EMAIL',
	SIGNIN_ERROR_SIGNIN: 'SIGNIN_ERROR_SIGNIN',
	SIGNIN_ERROR_RETRY: 'SIGNIN_ERROR_RETRY',
	SIGNUP_ERROR_DUPLICATED_EMAIL: 'SIGNUP_ERROR_DUPLICATED_EMAIL',
	PRODUCT_CONTACT_US_DESCRIPTION: 'PRODUCT_CONTACT_US_DESCRIPTION',
	PRODUCT_CONTACT_US_BUTTON: 'PRODUCT_CONTACT_US_BUTTON',
	FOOTER_PAGE_PAYMENT_TYPE_TITLE: 'FOOTER_PAGE_PAYMENT_TYPE_TITLE',
	FOOTER_PAGE_DEVOLUTIONS: 'FOOTER_PAGE_DEVOLUTIONS',
	FOOTER_PAGE_PAYMENT_TYPE_DESCRIPTION: 'FOOTER_PAGE_PAYMENT_TYPE_DESCRIPTION',
	FOOTER_PAGE_PAYMENT_TYPE_DESCRIPTION_L1:
		'FOOTER_PAGE_PAYMENT_TYPE_DESCRIPTION_L1',
	FOOTER_PAGE_PAYMENT_TYPE_DESCRIPTION_L2:
		'FOOTER_PAGE_PAYMENT_TYPE_DESCRIPTION_L2',
	FOOTER_PAGE_PAYMENT_TYPE_DESCRIPTION_L3:
		'FOOTER_PAGE_PAYMENT_TYPE_DESCRIPTION_L3',
	FOOTER_PAGE_PAYMENT_TYPE_DESCRIPTION_L4:
		'FOOTER_PAGE_PAYMENT_TYPE_DESCRIPTION_L4',
	FOOTER_PAGE_INVOICE_QUESTION: 'FOOTER_PAGE_INVOICE_QUESTION',
	FOOTER_PAGE_INVOICE_ANSWER: 'FOOTER_PAGE_INVOICE_ANSWER',
	FOOTER_PAGE_SECURITY_TITLE: 'FOOTER_PAGE_SECURITY_TITLE',
	FOOTER_PAGE_SECURITY_DESCRIPTION: 'FOOTER_PAGE_SECURITY_DESCRIPTION',
	FOOTER_PAGE_DELIVERY: 'FOOTER_PAGE_DELIVERY',
	DELIVERY_TITLE: 'DELIVERY_TITLE',
	DELIVERY_DESCRIPTION_1: 'DELIVERY_DESCRIPTION_1',
	DELIVERY_DESCRIPTION_2: 'DELIVERY_DESCRIPTION_2',
	RETURNORDER_CHECK_TERMS_AND_CONDITIONS:
		'RETURNORDER_CHECK_TERMS_AND_CONDITIONS',
	RETURNORDER_DEVOLUTION_N: 'RETURNORDER_DEVOLUTION_N',
	RETURNORDER_ORDER_N: 'RETURNORDER_ORDER_N',
	RETURNORDER_BUY_DATE: 'RETURNORDER_BUY_DATE',
	RETURNORDER_PRODUCTS: 'RETURNORDER_PRODUCTS',
	RETURNORDER_SUBTOTAL: 'RETURNORDER_SUBTOTAL',
	RETURNORDER_TOTAL: 'RETURNORDER_TOTAL',
	RETURNORDER_SHIPPING: 'RETURNORDER_SHIPPING',
	RETURNORDER_CONTACT_SUPPORT: 'RETURNORDER_CONTACT_SUPPORT',
	RETURNORDER_NO_SELECTED_PRODUCTS: 'RETURNORDER_NO_SELECTED_PRODUCTS',
	RETURNORDER_SELECT_TERMS_CONDITIONS: 'RETURNORDER_SELECT_TERMS_CONDITIONS',
	RETURNORDER_FIRST_TIME_PREVIEW_MESSAGE:
		'RETURNORDER_FIRST_TIME_PREVIEW_MESSAGE',
	NEWS_LETTER_SUCCESS: 'NEWS_LETTER_SUCCESS',
	NEWS_LETTER_ERROR: 'NEWS_LETTER_ERROR',
	NEWS_LETTER_ALREADY_SUSCRIBED: 'NEWS_LETTER_ALREADY_SUSCRIBED',
	PASSWORD_RECOVERY: 'PASSWORD_RECOVERY',
	PASSWORD_RECOVERY_DESCRIPTION: 'PASSWORD_RECOVERY_DESCRIPTION',
	PASSWORD_RECOVERY_EMAIL: 'PASSWORD_RECOVERY_EMAIL',
	PASSWORD_RECOVERY_ERROR_INVALID_EMAIL:
		'PASSWORD_RECOVERY_ERROR_INVALID_EMAIL',
	PASSWORD_RECOVERY_ERROR_RETRY: 'PASSWORD_RECOVERY_ERROR_RETRY',
	PASSWORD_RECOVERY_SUBMIT: 'PASSWORD_RECOVERY_SUBMIT',
	PASSWORD_RECOVERY_SUCCESS: 'PASSWORD_RECOVERY_SUCCESS',
	PASSWORD_RECOVERY_ERROR_EMAIL_NOT_FOUND:
		'PASSWORD_RECOVERY_ERROR_EMAIL_NOT_FOUND',
	PASSWORD_RECOVERY_ERROR_EMAIL_NOT_SENT:
		'PASSWORD_RECOVERY_ERROR_EMAIL_NOT_SENT',
	PASSWORD_RECOVERY_ERROR: 'PASSWORD_RECOVERY_ERROR',
	PASSWORD_RECOVERY_ERROR_EMAIL: 'PASSWORD_RECOVERY_ERROR_EMAIL',
	Draft: 'Draft',
	'Pending payment': 'Pending payment',
	Paid: 'Paid',
	'Payment failed': 'Payment failed',
	'Paid pending review': 'Paid pending review',
	Reviewed: 'Reviewed',
	'Sent to SIMSS': 'Sent to SIMSS',
	'Error sending to SIMSS': 'Error sending to SIMSS',
	'Carrier notified': 'Carrier notified',
	'Error notifying carrier': 'Error notifying carrier',
	Shipped: 'Shipped',
	Delivered: 'Delivered',
	Completed: 'Completed',
	Cancelled: 'Cancelled',
	PAGE_NOT_FOUND: 'PAGE_NOT_FOUND',
	NO_PRODUCTS_FOUND: 'NO_PRODUCTS_FOUND',
}

export const pt: ResourceLanguage = {
	translation: translations,
}

export default pt
