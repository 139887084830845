import { Box } from '@mui/material'
import { IRoute } from '../../../interfaces/route.interface'
import {
	footerPageTitle,
	footerPageText,
	footerPageSubTitle,
} from '.'

export const PurchaseConditions = (
	props: IRoute
) => {
	return (
		<Box
			sx={{
				margin: '5%',
				mb: '15%',
			}}
		>
			{footerPageTitle(
				'Formas de pago',
				props.isMobile
			)}
			{footerPageText(
				<>
					Puedes utilizar los siguientes{' '}
					<b>medios de pago</b>: Visa, Visa
					Electron, Mastercard, Maestro, JCB y
					UPAY.
				</>,
				props.isMobile
			)}
			{footerPageText(
				<>
					En el caso de que el banco{' '}
					<b>rechace</b> tu tarjeta comprueba que:
				</>,
				props.isMobile
			)}
			<ul>
				<li>La tarjeta no esté caducada.</li>
				<li>
					Que tu tarjeta no exceda la fecha de
					validez.
				</li>
				<li>
					Puede que se haya alcanzado el límite de
					la tarjeta.
				</li>
				<li>
					Consulta con tu banco que la tarjeta no
					haya excedido el importe permitido para
					efectuar compras.
				</li>
			</ul>
			{footerPageSubTitle(
				`¿Puedo obtener una factura a nombre de mi
				empresa?`,
				props.isMobile
			)}
			{footerPageText(
				`Comprueba que has rellenado correctamente
				todos los campos necesarios. ¿Puedo
				obtener una factura a nombre de mi
				empresa? Sí.`,
				props.isMobile
			)}
			{footerPageTitle(
				'Seguridad',
				props.isMobile
			)}
			{footerPageText(
				`Solamente tienes que marcar la opción de
				«empresa» en datos personales y cubrir los
				datos fiscales que te pedimos. Seguridad
				Para Fina Ejerique, la seguridad en las
				compras online es fundamental. Todas las
				transacciones se llevan a cabo a través de
				sistemas de pago seguros y los datos
				confidenciales del pago son transmitidos
				directamente y de forma encriptada (SSL) a
				la entidad. Contamos con los certificados
				SSL de Sectigo RSA Domain Validation
				Secure Server CA y con los certificados de
				seguridad de transacciones online:
				Verified by VISA, MasterCard SecureCode y
				American Express Safekey`,
				props.isMobile
			)}
			{footerPageTitle('Envíos', props.isMobile)}
			{footerPageText(
				<b>
					ENTREGA A DOMICILIO GRATUITA A PARTIR DE
					50€
				</b>,
				props.isMobile
			)}
			{footerPageText(
				<>
					Los <b>plazos de entrega</b> van de 1 a
					3 días laborables en la península (1 o 2
					días más en las Islas Baleares). No se
					realizan envíos a las Islas Canarias.
				</>,
				props.isMobile
			)}
			{footerPageText(
				<>
					<b>
						Los plazos de entrega son para los
					</b>
					pedidos realizados de lunes a viernes
					antes de las 09.00h am. Después de esa
					hora, el plazo comenzará al siguiente
					día hábil. En periodos especiales como
					Black Friday o Rebajas, el plazo de
					envío puede ser superior al habitual.
				</>,
				props.isMobile
			)}
			{footerPageTitle(
				'Devoluciones y cambios',
				props.isMobile
			)}
			{footerPageText(
				<>
					Las devoluciones son GRATUITAS. Dispones
					de <b>30 días</b> tras la recepción de
					tu pedido para solicitar su devolución.
					Para ello, puedes solicitar la recogida
					gratuita a domicilio y enviaremos un
					transportista a recoger tu paquete:
				</>,
				props.isMobile
			)}
			{footerPageSubTitle(
				`1. Solicita la recogida a domicilio en
				finaejerique.es`,
				props.isMobile
			)}
			{footerPageText(
				`Si estás registrado, accede al apartado
				Historial y detalles del pedido en la
				sección Mi cuenta. A continuación,
				selecciona el pedido donde se encuentra
				el/los producto/s a devolver y una vez
				dentro, selecciónalo y dale a la opción
				Solicitar una devolución. Una vez
				realizados todos estos pasos procederemos
				a la recogida del paquete y si el producto
				se encuentra en buen estado se realizará
				el reembolso de la cantidad total del
				producto.`,
				props.isMobile
			)}
			{footerPageSubTitle(
				`2. Prepara tu paquete`,
				props.isMobile
			)}
			{footerPageText(
				`Empaqueta tus artículos de forma segura,
				en el paquete original si es posible, y
				pega la etiqueta de devolución en el
				paquete. Podrás obtener un albarán de
				devolución en Devolución de mercancías,
				seleccionando tu pedido e imprimiendo el
				'Formulario de devolución'.`,
				props.isMobile
			)}
			{footerPageSubTitle(
				`3. El transportista acudirá a tu domicilio
				para recoger tu paquete`,
				props.isMobile
			)}
			{footerPageText(
				`Una vez realizados los pasos anteriores,
				el transportista acudirá a tu domicilio
				para proceder con la recogida. Si se trata
				de una devolución, el reembolso se hará
				efectivo en el mismo método de pago
				utilizado en la compra y en un plazo de
				entre 3 y 12 días laborables desde que
				recibimos tu paquete en nuestros
				almacenes.`,
				props.isMobile
			)}
		</Box>
	)
}
