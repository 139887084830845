import { IEntityIdentifier } from '../redirect-handler/redirect-handler'

function encodeBase64(str: string): string {
	return btoa(str)
}

function decodeBase64(str: string): string {
	return atob(str)
}

function textoToSlug(texto: string): string {
	return texto
		.toLowerCase()
		.replace(/ /g, '-')
		.replace(/[^\w-]+/g, '')
}

function getUrlParamWithEID(
	name: string,
	type: 'product' | 'category',
	id: number | undefined
): string {
	if (!id) {
		return ''
	}

	const identifier: IEntityIdentifier = {
		type,
		id,
	}

	const url = `${textoToSlug(name)}?eid=${encodeBase64(
		JSON.stringify(identifier)
	)}`

	return url
}

function getEntityEID(searchParams: URLSearchParams): number | null {
	const eid: string | null = searchParams.get('eid')

	const eidParsed = eid ? JSON.parse(decodeBase64(eid)) : null

	return !eid ? null : Number(eidParsed.id)
}

export {
	encodeBase64,
	decodeBase64,
	textoToSlug,
	getUrlParamWithEID,
	getEntityEID,
}
