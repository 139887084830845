import Cookies from 'js-cookie'

export interface IRequestManagerResponse {
	status: number
	data: any
}

export const isRequestSuccessfull = (
	status: number
) => {
	return [200, 201].includes(status)
}

export async function RequestManager(
	method: 'GET' | 'POST' | 'PUT' | 'DELETE',
	path: string,
	authenticate = true,
	body?: object,
	language = 'es'
): Promise<IRequestManagerResponse> {
	const conentType = 'application/json'

	const params: RequestInit = {
		method,
	}

	const headers: HeadersInit = {}

	if (authenticate) {
		const accessToken = Cookies.get('accessToken')

		if (!accessToken) {
			window.location.href = `/${language}/login`
			throw new Error('No access token')
		}

		const authorization = `Bearer ${accessToken}`

		headers.Authorization = authorization
	}

	if (body) {
		const bodyData = JSON.stringify(body)
		params.body = bodyData
		headers['Content-Type'] = conentType
	}

	if (Object.keys(headers).length > 0) {
		params.headers = headers
	}

	try {
		const response = await fetch(
			`${process.env.REACT_APP_CORE_API_URL}/${path}`,
			params
		)

		if (response.status === 401) {
			Cookies.remove('accessToken')
			window.location.href = `/${language}/login`
			throw new Error('Unauthorized')
		} else if (response.status === 404) {
			window.location.href = '/404'
			throw new Error('Not Found')
		} else if (response.status === 500) {
			// TODO
			throw new Error('Server error')
		} else {
			const textData = await response.text()
			const data =
				textData.length > 0
					? JSON.parse(textData)
					: {}
			return {
				status: response.status,
				data: data,
			}
		}
	} catch (error) {
		throw new Error('Unknown request error')
	}
}
