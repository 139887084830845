import { Box } from '@mui/material'
import { IRoute } from '../../../interfaces/route.interface'
import {
	footerPageSubTitle,
	footerPageText,
	footerPageTitle,
} from '.'

export const ReturnsPage = (props: IRoute) => {
	return (
		<Box
			mx={'5%'}
			my={'5%'}
			mb={'15%'}
		>
			{footerPageTitle(
				'Devoluciones y cambios',
				props.isMobile
			)}
			{footerPageSubTitle(
				`Las devoluciones son GRATUITAS.`,
				props.isMobile
			)}
			{footerPageText(
				`Dispones de 30 días tras la recepción de tu pedido para  solicitar su devolución. Para ello, puedes solicitar la recogida gratuita a domicilio y enviaremos un transportista a recoger tu paquete:`,
				props.isMobile
			)}
			{footerPageText(
				`Los plazos de entrega son para los pedidos realizados de lunes a viernes antes de las 09.00h am. Después de esa hora, el plazo comenzará al siguiente día hábil. En periodos especiales como Black Friday o Rebajas, el plazo de envío puede ser superior al habitual.`,
				props.isMobile
			)}
			{footerPageSubTitle(
				`1. Solicita la recogida a domicilio en finaejerique.es`,
				props.isMobile
			)}
			{footerPageText(
				`Si estás registrado, accede al apartado Historial y detalles del pedido en la sección Mi cuenta. A continuación, selecciona el pedido donde se encuentra el/los producto/s a devolver y una vez dentro, selecciónalo y dale a la opción Solicitar una devolución. Una vez realizados todos estos pasos procederemos a la recogida del paquete y si el producto se encuentra en buen estado se realizará el reembolso de la cantidad total del producto.`,
				props.isMobile
			)}
			{footerPageSubTitle(
				`2. Prepara tu paquete`,
				props.isMobile
			)}
			{footerPageText(
				`Empaqueta tus artículos de forma segura, en el paquete original si es posible, y pega la etiqueta de devolución en el paquete.`,
				props.isMobile
			)}
			{footerPageText(
				`Podrás obtener un albarán de devolución en Devolución de mercancías, seleccionando tu pedido e imprimiendo el 'Formulario de devolución'.`,
				props.isMobile
			)}
			{footerPageSubTitle(
				`3. El transportista acudirá a tu domicilio para recoger tu paquete`,
				props.isMobile
			)}
			{footerPageText(
				`Una vez realizados los pasos anteriores, el transportista acudirá a tu domicilio para proceder con la recogida.`,
				props.isMobile
			)}
			{footerPageText(
				`Si se trata de una devolución, el reembolso se hará efectivo en el mismo método de pago utilizado en la compra y en un plazo de entre 3 y 12 días laborables desde que recibimos tu paquete en nuestros almacenes.`,
				props.isMobile
			)}
		</Box>
	)
}
