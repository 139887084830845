import { Grid } from '@mui/material'
import { AccountOverviewShortcut } from './account-overview-shortcut.component'
import {
	Inventory,
	KeyboardReturn,
	LockReset,
	MenuBook,
	SupervisedUserCircleOutlined,
} from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import {
	AccountRoutes,
	Translations,
} from '../../translations/translations.enum'
import translations from '../../translations'

export function AccountOverview() {
	const { t } = useTranslation()

	return (
		<Grid
			container
			spacing={2}
		>
			<Grid
				item
				xs={12}
				md={6}
			>
				<AccountOverviewShortcut
					icon={<SupervisedUserCircleOutlined />}
					text={t(AccountRoutes.MY_INFO)}
					subText={t(AccountRoutes.MY_INFO)}
					path={'my-info'}
				/>
			</Grid>
			<Grid
				item
				xs={12}
				md={6}
			>
				<AccountOverviewShortcut
					icon={<Inventory />}
					text={t(AccountRoutes.ORDER_HISTORY)}
					subText={t(
						Translations.ORDER_HISTORY_DESCRIPTION
					)}
					path={'orders'}
				/>
			</Grid>
			<Grid
				item
				xs={12}
				md={6}
			>
				<AccountOverviewShortcut
					icon={<MenuBook />}
					text={t(AccountRoutes.ADDRESS_BOOK)}
					subText={t(
						Translations.ADDRESS_BOOK_DESCRIPTION
					)}
					path={'addresses'}
				/>
			</Grid>
			<Grid
				item
				xs={12}
				md={6}
			>
				<AccountOverviewShortcut
					icon={<LockReset />}
					text={t(AccountRoutes.CHANGE_PASSWORD)}
					subText={t(
						Translations.PASSWORD_CHANGE_DESCRIPTION
					)}
					path={'change-password'}
				/>
			</Grid>
			<Grid
				item
				xs={12}
				md={6}
			>
				<AccountOverviewShortcut
					icon={<KeyboardReturn />}
					text={t(AccountRoutes.RETURNS)}
					subText={t(
						Translations.RETURN_DESCRIPTION
					)}
					path={'returns'}
				/>
			</Grid>
		</Grid>
	)
}
