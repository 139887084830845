import {
	Box,
	Grid,
	Typography,
} from '@mui/material'
import { ILink } from '../../interfaces/link.interface'
import { Link, useParams } from 'react-router-dom'
import '../../translations'
import { useTranslation } from 'react-i18next'
import { Translations } from '../../translations/translations.enum'

export const FooterLegal = () => {
	const { language } = useParams()
	const { t } = useTranslation()
	const links: ILink[] = [
		{
			title: Translations.COOKIE_POLICY,
			url: `/${language}/cookie-policy`,
		},
		{
			title: Translations.TERMS_CONDITIONS,
			url: `/${language}/terms-and-conditions`,
		},
		{
			title: Translations.PRIVACY_POLICY,
			url: `/${language}/privacy-policy`,
		},
		{
			title: Translations.FAQS,
			url: `/${language}/faqs`,
		},
	]

	return (
		<Grid container>
			{links.map((link: ILink, index: number) => (
				<Grid
					item
					xs={12}
					sm={6}
					md={3}
					key={'legal-link-' + index}
				>
					<Link
						key={index}
						to={link.url}
						style={{
							textDecoration: 'none',
							padding: '0 10px',
						}}
					>
						<Typography
							color={'primary.main'}
							align='center'
						>
							{t(link.title)}
						</Typography>
					</Link>
				</Grid>
			))}
		</Grid>
	)
}
