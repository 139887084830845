import { PaymentOutlined } from '@mui/icons-material'
import {
	Box,
	Checkbox,
	Container,
	FormControlLabel,
	FormHelperText,
	Typography,
} from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import 'react-phone-number-input/style.css'
import { useNavigate, useParams } from 'react-router-dom'
import BizumLogo from '../../assets/payment-logo/bizum-color.svg'
import { IOrderRequest } from '../../interfaces/order-request.interface'
import {
	Translations,
	Payment as PaymentTranslations,
} from '../../translations/translations.enum'
import { ICartUpdate } from '../../utils/cart.util'
import { CartDivider } from '../cart/cart-divider'
import { BlackButton } from '../product/black-button'
import { RequestManager } from '../utils'
import PaymentType from './payment-type'

export interface BizumAuthResponse {
	url: string
	Ds_SignatureVersion: string
	Ds_MerchantParameters: string
	Ds_Signature: string
}

export interface IPaymentProps {
	nextStep: () => void
	setCart: ICartUpdate
	order?: IOrderRequest
}

export enum PaymentMethod {
	REDSYS = 'redsys',
	BIZUM = 'bizum',
}

const Payment = (props: IPaymentProps) => {
	const { t } = useTranslation()
	const { language } = useParams()
	const [paymentMethod, setPaymentMethod] = useState<PaymentMethod | null>(null)

	const [form, setForm] = useState<BizumAuthResponse | null>(null)

	const navigate = useNavigate()

	const isCreditCard = paymentMethod === PaymentMethod.REDSYS
	const isBizum = paymentMethod === PaymentMethod.BIZUM

	const formRef = useRef<HTMLFormElement>(null) // Crear la referencia

	const handleBizumPayment = () => {
		formRef.current?.submit()
		//delay 1s to be sure the paywall is loaded
		setTimeout(() => {
			navigate(`/${language}/processing-payment`)
		}, 1000)
	}

	useEffect(() => {
		fetch(
			`${process.env.REACT_APP_CORE_API_URL}/redsys/bizum/${props.order?.redsysId}`,
			{
				method: 'POST',
			}
		)
			.then((response) => response.json())
			.then((data) => {
				setForm(data)
			})
	}, [])

	const [formData, setFormData] = useState<any>({
		termsAccepted: false,
		emailAdvertising: false,
		id: props.order?.id,
		paymentMethod: isCreditCard ? 'card' : 'bizum',
	})

	const [errors, setErrors] = useState<any>({})

	const validateForm = () => {
		let tempErrors: any = {}

		if (!formData.termsAccepted) {
			tempErrors.termsAccepted = t(
				PaymentTranslations.PAYMENT_ERROR_TERMS_CONDITIONS
			)
		}

		if (!paymentMethod) {
			tempErrors.paymentMethod = t(
				PaymentTranslations.PAYMENT_ERROR_PAYMENT_METHOD
			)
		}

		if (!props.order?.id) {
			tempErrors.orderId = t(
				PaymentTranslations.PAYMENT_ERROR_CREATING_ORDER_ID
			)
		}

		setErrors(tempErrors)
		const notErrors = Object.keys(tempErrors).length === 0
		if (notErrors)
			setFormData({
				...formData,
			})
		return notErrors
	}

	const handleChange = (event: any) => {
		const { name, value, type, checked } = event.target

		setFormData({
			...formData,
			[name]: type === 'checkbox' ? checked : value,
		})
	}

	const handleSubmit = async () => {
		if (validateForm()) {
			const response = await RequestManager(
				'POST',
				`order/pre-pay`,
				true,
				formData
			)

			if ([200, 201].includes(response.status)) {
				if (isCreditCard) {
					props.nextStep?.()
				} else if (isBizum) {
					handleBizumPayment()
				} else {
					console.error(
						t(PaymentTranslations.PAYMENT_ERROR_PAYMENT_METHOD_NOT_FOUND)
					)
				}
			} else {
				setErrors({
					...errors,
					orderId: t(PaymentTranslations.PAYMENT_ERROR_CREATING_ORDER_RETRY),
				})
			}
		}
	}

	return (
		<Container
			component='main'
			maxWidth='sm'
			sx={{
				padding: '5% 2%',
				width: '90%',
				minWidth: '75%',
				fontFamily: 'Bembo',
			}}
		>
			<Typography
				component='h1'
				variant='h5'
				fontFamily={'Bembo'}
				sx={{
					mb: '2rem',
					fontSize: '2rem',
				}}
			>
				Payment
			</Typography>

			{errors.paymentMethod && (
				<FormHelperText error>{errors.paymentMethod}</FormHelperText>
			)}
			<CartDivider />

			<Box
				sx={{
					border: isCreditCard ? 'solid 1px #000' : 'solid 1px #e0e0e0',
					marginTop: '1rem',
				}}
			>
				<PaymentType
					title={t(PaymentTranslations.PAYMENT_CREDIT_CARD)}
					description={t(PaymentTranslations.PAYMENT_CREDIT_CARD_DESCRIPTION)}
					icon={<PaymentOutlined fontSize={'large'} />}
					isSelected={isCreditCard}
					onClick={() => setPaymentMethod(PaymentMethod.REDSYS)}
				/>
			</Box>

			<Box
				sx={{
					border: isBizum ? 'solid 1px #000' : 'solid 1px #e0e0e0',
					marginY: '1rem',
				}}
			>
				<PaymentType
					title={t(PaymentTranslations.PAYMENT_BIZUM)}
					description={t(PaymentTranslations.PAYMENT_BIZUM_DESCRIPTION)}
					icon={<img src={BizumLogo} />}
					isSelected={isBizum}
					onClick={() => setPaymentMethod(PaymentMethod.BIZUM)}
				/>
				{isBizum && props.order?.redsysId && form && (
					<form
						ref={formRef}
						action={form.url}
						method='post'
						target='_blank'
					>
						<input
							type='hidden'
							id='Ds_SignatureVersion'
							name='Ds_SignatureVersion'
							value={form.Ds_SignatureVersion}
						/>
						<input
							type='hidden'
							id='Ds_MerchantParameters'
							name='Ds_MerchantParameters'
							value={form.Ds_MerchantParameters}
						/>
						<input
							type='hidden'
							id='Ds_Signature'
							name='Ds_Signature'
							value={form.Ds_Signature}
						/>
					</form>
				)}
			</Box>

			<CartDivider />
			<Box
				my='5%'
				gap={2}
				sx={{
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<FormControlLabel
					control={
						<Checkbox
							value='emailAdvertising'
							color='primary'
							name='emailAdvertising'
							checked={formData.emailAdvertising}
							onChange={handleChange}
						/>
					}
					label={t(Translations.CHECK_EMAIL_COMMUNICATIONS)}
				/>
				<FormControlLabel
					control={
						<Checkbox
							value='termsAccepted'
							color='primary'
							name='termsAccepted'
							checked={formData.termsAccepted}
							onChange={handleChange}
						/>
					}
					label={t(Translations.CHECK_TERMS_AND_CONDITIONS)}
				/>
				{errors.termsAccepted && (
					<FormHelperText error>{errors.termsAccepted}</FormHelperText>
				)}
			</Box>
			{errors.orderId && (
				<FormHelperText error>{errors.orderId}</FormHelperText>
			)}
			<BlackButton
				inverted={true}
				text={t(PaymentTranslations.PAYMENT_CONFIRM_AND_PAY)}
				onClick={() => handleSubmit()}
			/>
		</Container>
	)
}

export default Payment
