import React from 'react'
import { IRoute } from '../../../interfaces/route.interface'
import {
	Container,
	List,
	ListItem,
	ListItemText,
	Paper,
	Typography,
} from '@mui/material'
import {
	footerPageText,
	footerPageTitle,
} from '.'

export default function PrivacyPolicy(
	props: IRoute
) {
	return (
		<Container>
			<Paper
				elevation={3}
				style={{
					padding: '4rem',
					margin: '20px 0',
				}}
			>
				<Typography
					variant='h4'
					gutterBottom
				>
					POLÍTICA DE PRIVACIDAD
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					En GLOBOTEX, S.L. nos preocupamos por la
					privacidad y la transparencia. A
					continuación, le indicamos en detalle
					los tratamientos de datos personales que
					realizamos, así como toda la información
					relativa a los mismos.
				</Typography>

				<Typography
					variant='h5'
					gutterBottom
				>
					Tratamiento de los datos de clientes
				</Typography>

				<Typography
					variant='h6'
					gutterBottom
				>
					Información básica sobre Protección de
					datos
				</Typography>

				<List>
					<ListItem>
						<ListItemText
							primary='Responsable'
							secondary='GLOBOTEX, S.L.'
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary='Finalidad'
							secondary='Prestar los servicios solicitados y enviar comunicaciones comerciales.'
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary='Legitimación'
							secondary='Ejecución de un contrato. Interés legítimo del Responsable.'
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary='Destinatarios'
							secondary='No se cederán datos a terceros, salvo obligación legal.'
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary='Derechos'
							secondary='Tiene derecho a acceder, rectificar y suprimir los datos, así como otros derechos, indicados en la información adicional, que puede ejercer dirigiéndose a legal@finaejerique.es o C/ ALMACIL, 6 Y 8 BAJOS - 46920 - MISLATA.'
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary='Procedencia'
							secondary='El propio interesado.'
						/>
					</ListItem>
				</List>

				<Typography
					variant='h5'
					gutterBottom
				>
					Información completa sobre Protección de
					Datos
				</Typography>

				<Typography
					variant='body1'
					paragraph
				>
					1. ¿Quién es el responsable del
					tratamiento de sus datos?
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					GLOBOTEX, S.L.
					<br />
					B97230692
					<br />
					C/ ALMACIL, 6 Y 8 BAJOS - 46920 -
					MISLATA - VALENCIA
					<br />
					963135167
					<br />
					legal@finaejerique.es
				</Typography>

				<Typography
					variant='body1'
					paragraph
				>
					2. ¿Con qué finalidad tratamos sus datos
					personales?
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					En GLOBOTEX, S.L. tratamos la
					información que nos facilitan las
					personas interesadas con el fin de
					realizar la gestión administrativa,
					contable y fiscal de los servicios
					solicitados, así como enviar
					comunicaciones comerciales sobre
					nuestros productos y servicios. En el
					caso de que no facilite sus datos
					personales, no podremos cumplir con las
					finalidades descritas.
					<br />
					No se van a tomar decisiones
					automatizadas en base a los datos
					proporcionados.
				</Typography>

				<Typography
					variant='body1'
					paragraph
				>
					3. ¿Por cuánto tiempo conservaremos sus
					datos?
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					Los datos se conservarán mientras el
					interesado no solicite su supresión, y
					en su caso, durante los años necesarios
					para cumplir con las obligaciones
					legales.
				</Typography>

				<Typography
					variant='body1'
					paragraph
				>
					4. ¿Cuál es la legitimación para el
					tratamiento de sus datos?
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					Le indicamos la base legal para el
					tratamiento de sus datos:
				</Typography>
				<List>
					<ListItem>
						<ListItemText
							primary='Ejecución de un contrato'
							secondary='Gestión fiscal, contable y administrativa de clientes. (RGPD art. 6.1.b).'
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary='Interés legítimo del Responsable'
							secondary='Envío de comunicaciones comerciales incluso por vía electrónica. (RGPD Considerando 47, LSSICE art. 21.2).'
						/>
					</ListItem>
				</List>

				<Typography
					variant='body1'
					paragraph
				>
					5. ¿A qué destinatarios se comunicarán
					sus datos?
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					No se cederán datos a terceros, salvo
					obligación legal.
				</Typography>

				<Typography
					variant='body1'
					paragraph
				>
					6. Transferencias de datos a terceros
					países
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					No están previstas transferencias de
					datos a terceros países.
				</Typography>

				<Typography
					variant='body1'
					paragraph
				>
					7. ¿Cuáles son sus derechos cuando nos
					facilita sus datos?
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					Cualquier persona tiene derecho a
					obtener confirmación sobre si en
					GLOBOTEX, S.L. estamos tratando, o no,
					datos personales que les conciernan.
				</Typography>

				<Typography
					variant='body1'
					paragraph
				>
					Las personas interesadas tienen derecho
					a acceder a sus datos personales, así
					como a solicitar la rectificación de los
					datos inexactos o, en su caso, solicitar
					su supresión cuando, entre otros
					motivos, los datos ya no sean necesarios
					para los fines que fueron recogidos.
					Igualmente, tienen derecho a la
					portabilidad de sus datos.
				</Typography>

				<Typography
					variant='body1'
					paragraph
				>
					En determinadas circunstancias, los
					interesados podrán solicitar la
					limitación del tratamiento de sus datos,
					en cuyo caso únicamente los
					conservaremos para el ejercicio o la
					defensa de reclamaciones.
				</Typography>

				<Typography
					variant='body1'
					paragraph
				>
					En determinadas circunstancias y por
					motivos relacionados con su situación
					particular, los interesados podrán
					oponerse al tratamiento de sus datos. En
					este caso, GLOBOTEX, S.L. dejará de
					tratar los datos, salvo por motivos
					legítimos imperiosos, o el ejercicio o
					la defensa de posibles reclamaciones.
				</Typography>

				<Typography
					variant='body1'
					paragraph
				>
					Podrá ejercitar materialmente sus
					derechos de la siguiente forma:
					dirigiéndose a legal@finaejerique.es o
					C/ ALMACIL, 6 Y 8 BAJOS - 46920 -
					MISLATA.
				</Typography>

				<Typography
					variant='body1'
					paragraph
				>
					Cuando se realice el envío de
					comunicaciones comerciales utilizando
					como base jurídica el interés legítimo
					del responsable, el interesado podrá
					oponerse al tratamiento de sus datos con
					ese fin.
				</Typography>

				<Typography
					variant='body1'
					paragraph
				>
					Si ha otorgado su consentimiento para
					alguna finalidad concreta, tiene derecho
					a retirar el consentimiento otorgado en
					cualquier momento, sin que ello afecte a
					la licitud del tratamiento basado en el
					consentimiento previo a su retirada.
				</Typography>

				<Typography
					variant='body1'
					paragraph
				>
					En caso de que sienta vulnerados sus
					derechos en lo concerniente a la
					protección de sus datos personales,
					especialmente cuando no haya obtenido
					satisfacción en el ejercicio de sus
					derechos, puede presentar una
					reclamación ante la Autoridad de Control
					en materia de Protección de Datos
					competente a través de su sitio web:
					www.aepd.es.
				</Typography>

				<Typography
					variant='body1'
					paragraph
				>
					8. ¿Cómo hemos obtenido sus datos?
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					Los datos personales que tratamos en
					GLOBOTEX, S.L. proceden de: El propio
					interesado.
				</Typography>

				<Typography
					variant='body1'
					paragraph
				>
					Las categorías de datos que se tratan
					son:
				</Typography>
				<List>
					<ListItem>
						<ListItemText primary='Datos identificativos' />
					</ListItem>
					<ListItem>
						<ListItemText primary='Direcciones postales y electrónicas' />
					</ListItem>
					<ListItem>
						<ListItemText primary='Información comercial' />
					</ListItem>
				</List>

				<Typography
					variant='h5'
					gutterBottom
				>
					Tratamiento de los datos de potenciales
					clientes y contactos
				</Typography>

				<Typography
					variant='h6'
					gutterBottom
				>
					Información básica sobre Protección de
					datos
				</Typography>

				<List>
					<ListItem>
						<ListItemText
							primary='Responsable'
							secondary='GLOBOTEX, S.L.'
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary='Finalidad'
							secondary='Atender su solicitud y enviarle comunicaciones comerciales.'
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary='Legitimación'
							secondary='Ejecución de un contrato. Consentimiento del interesado. Interés legítimo del Responsable.'
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary='Destinatarios'
							secondary='No se cederán datos a terceros, salvo obligación legal.'
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary='Derechos'
							secondary='Tiene derecho a acceder, rectificar y suprimir los datos, así como otros derechos, indicados en la información adicional, que puede ejercer dirigiéndose a legal@finaejerique.es o C/ ALMACIL, 6 Y 8 BAJOS - 46920 - MISLATA.'
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary='Procedencia'
							secondary='El propio interesado.'
						/>
					</ListItem>
				</List>

				<Typography
					variant='h5'
					gutterBottom
				>
					Información completa sobre Protección de
					Datos
				</Typography>

				<Typography
					variant='body1'
					paragraph
				>
					1. ¿Quién es el responsable del
					tratamiento de sus datos?
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					GLOBOTEX, S.L.
					<br />
					B97230692
					<br />
					C/ ALMACIL, 6 Y 8 BAJOS - 46920 -
					MISLATA - VALENCIA
					<br />
					963135167
					<br />
					legal@finaejerique.es
				</Typography>

				<Typography
					variant='body1'
					paragraph
				>
					2. ¿Con qué finalidad tratamos sus datos
					personales?
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					En GLOBOTEX, S.L. tratamos la
					información que nos facilitan las
					personas interesadas con el fin de
					realizar la gestión de potenciales
					clientes que se han interesado sobre
					nuestros productos y/o servicios, así
					como otros contactos comerciales. Envío
					de comunicaciones comerciales inclusive
					por vía electrónica. En el caso de que
					no facilite sus datos personales, no
					podremos cumplir con las finalidades
					descritas.
					<br />
					No se van a tomar decisiones
					automatizadas en base a los datos
					proporcionados.
				</Typography>

				<Typography
					variant='body1'
					paragraph
				>
					3. ¿Por cuánto tiempo conservaremos sus
					datos?
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					Los datos se conservarán mientras el
					interesado no solicite su supresión.
				</Typography>

				<Typography
					variant='body1'
					paragraph
				>
					4. ¿Cuál es la legitimación para el
					tratamiento de sus datos?
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					Le indicamos la base legal para el
					tratamiento de sus datos:
				</Typography>
				<List>
					<ListItem>
						<ListItemText
							primary='Ejecución de un contrato'
							secondary='Gestión de potenciales clientes que se han interesado sobre nuestros productos y/o servicios. (RGPD, art. 6.1.b, LSSICE art.21).'
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary='Consentimiento del interesado'
							secondary='Enviar comunicaciones comerciales, inclusive por vía electrónica. (RGPD, art. 6.1.a).'
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary='Interés legítimo del Responsable'
							secondary='Gestión de los datos de contacto profesionales (LOPDGDD art.19, RGPD art. 6.1.f).'
						/>
					</ListItem>
				</List>

				<Typography
					variant='body1'
					paragraph
				>
					5. ¿A qué destinatarios se comunicarán
					sus datos?
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					No se cederán datos a terceros, salvo
					obligación legal.
				</Typography>

				<Typography
					variant='body1'
					paragraph
				>
					6. Transferencias de datos a terceros
					países
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					No están previstas transferencias de
					datos a terceros países.
				</Typography>

				<Typography
					variant='body1'
					paragraph
				>
					7. ¿Cuáles son sus derechos cuando nos
					facilita sus datos?
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					Cualquier persona tiene derecho a
					obtener confirmación sobre si en
					GLOBOTEX, S.L. estamos tratando, o no,
					datos personales que les conciernan.
				</Typography>

				<Typography
					variant='body1'
					paragraph
				>
					Las personas interesadas tienen derecho
					a acceder a sus datos personales, así
					como a solicitar la rectificación de los
					datos inexactos o, en su caso, solicitar
					su supresión cuando, entre otros
					motivos, los datos ya no sean necesarios
					para los fines que fueron recogidos.
					Igualmente, tienen derecho a la
					portabilidad de sus datos.
				</Typography>

				<Typography
					variant='body1'
					paragraph
				>
					En determinadas circunstancias, los
					interesados podrán solicitar la
					limitación del tratamiento de sus datos,
					en cuyo caso únicamente los
					conservaremos para el ejercicio o la
					defensa de reclamaciones.
				</Typography>

				<Typography
					variant='body1'
					paragraph
				>
					En determinadas circunstancias y por
					motivos relacionados con su situación
					particular, los interesados podrán
					oponerse al tratamiento de sus datos. En
					este caso, GLOBOTEX, S.L. dejará de
					tratar los datos, salvo por motivos
					legítimos imperiosos, o el ejercicio o
					la defensa de posibles reclamaciones.
				</Typography>

				<Typography
					variant='body1'
					paragraph
				>
					Podrá ejercitar materialmente sus
					derechos de la siguiente forma:
					dirigiéndose a legal@finaejerique.es o
					C/ ALMACIL, 6 Y 8 BAJOS - 46920 -
					MISLATA.
				</Typography>

				<Typography
					variant='body1'
					paragraph
				>
					Cuando se realice el envío de
					comunicaciones comerciales utilizando
					como base jurídica el interés legítimo
					del responsable, el interesado podrá
					oponerse al tratamiento de sus datos con
					ese fin.
				</Typography>

				<Typography
					variant='body1'
					paragraph
				>
					Si ha otorgado su consentimiento para
					alguna finalidad concreta, tiene derecho
					a retirar el consentimiento otorgado en
					cualquier momento, sin que ello afecte a
					la licitud del tratamiento basado en el
					consentimiento previo a su retirada.
				</Typography>

				<Typography
					variant='body1'
					paragraph
				>
					En caso de que sienta vulnerados sus
					derechos en lo concerniente a la
					protección de sus datos personales,
					especialmente cuando no haya obtenido
					satisfacción en el ejercicio de sus
					derechos, puede presentar una
					reclamación ante la Autoridad de Control
					en materia de Protección de Datos
					competente a través de su sitio web:
					www.aepd.es.
				</Typography>

				<Typography
					variant='body1'
					paragraph
				>
					8. ¿Cómo hemos obtenido sus datos?
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					Los datos personales que tratamos en
					GLOBOTEX, S.L. proceden de: El propio
					interesado.
				</Typography>

				<Typography
					variant='body1'
					paragraph
				>
					Las categorías de datos que se tratan
					son:
				</Typography>
				<List>
					<ListItem>
						<ListItemText primary='Datos identificativos' />
					</ListItem>
					<ListItem>
						<ListItemText primary='Direcciones postales y electrónicas' />
					</ListItem>
					<ListItem>
						<ListItemText primary='Información comercial' />
					</ListItem>
				</List>
			</Paper>
		</Container>
	)
}
