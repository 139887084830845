import { MobileHeader } from './mobile/header'
import { DesktopHeader } from './desktop/header'
import { useState, useEffect, memo } from 'react'
import { IDropdownLink } from '../../interfaces/dropdown-link.interface'
import { getHeaderBannerStickyHeight } from '../../utils/get-header-banner-height'
import { Box } from '@mui/material'
import { LanguageISOCode } from '../../enums/language-iso-code.enum'
import { ICartUpdate } from '../../utils/cart.util'
export interface IHeader {
	isMobile: boolean
	appBarHeight: string
	language: LanguageISOCode
	setLanguage: (language: LanguageISOCode) => void
	isLogged: boolean
	setIsLogged: (isLogged: boolean) => void
	setCart: ICartUpdate
}

export const Header = memo((props: IHeader) => {
	const [isSticky, setSticky] = useState(false)

	const STICKY_HEIGHT =
		getHeaderBannerStickyHeight()
	const headerBannerHeight = `${STICKY_HEIGHT}px`

	const handleScroll = () => {
		const scrollTop = window.scrollY
		setSticky(scrollTop > 2 * STICKY_HEIGHT)
	}

	useEffect(() => {
		window.addEventListener(
			'scroll',
			handleScroll
		)

		return () => {
			window.removeEventListener(
				'scroll',
				handleScroll
			)
		}
	}, [props.language, props.isLogged])

	return (
		<>
			{props.isMobile ? (
				<MobileHeader
					appBarHeight={props.appBarHeight}
					language={props.language}
					setLanguage={props.setLanguage}
				/>
			) : (
				<DesktopHeader
					isSticky={isSticky}
					headerBannerHeight={headerBannerHeight}
					language={props.language}
					setLanguage={props.setLanguage}
					isLogged={props.isLogged}
					setIsLogged={props.setIsLogged}
					setCart={props.setCart}
				/>
			)}
			{!props.isMobile && (
				<Box
					sx={{
						height: `${props.appBarHeight}`,
					}}
				></Box>
			)}
		</>
	)
})
