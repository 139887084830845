import {
	Box,
	Divider,
	Grid,
	Typography,
} from '@mui/material'
import { FooterNavColumn } from './footer-nav-column'
import {
	footerNavColumnMockAboutFinaEjerique,
	footerNavColumnMockCustomerCare,
} from './footer-nav-column-mock'
import {
	Facebook,
	Instagram,
	Pinterest,
	Twitter,
	YouTube,
} from '@mui/icons-material'
import { FooterForm } from './footer-form'
import { FooterLegal } from './footer-legal'
import { useParams } from 'react-router-dom'
import { Translations } from '../../translations/translations.enum'
import { useTranslation } from 'react-i18next'

export interface IFooter {
	isMobile: boolean
}

export const Footer = (props: IFooter) => {
	const { language } = useParams()
	const { t } = useTranslation()
	return (
		<Box
			component={'footer'}
			sx={{
				height: '30px',
				direction: 'column',
			}}
		>
			<Box
				padding={'2rem 18px 0'}
				bgcolor={'background.paper'}
			>
				<Grid container>
					<FooterNavColumn
						links={footerNavColumnMockCustomerCare(
							language || 'en'
						)}
					/>
					<FooterNavColumn
						links={footerNavColumnMockAboutFinaEjerique(
							language || 'en'
						)}
					/>
				</Grid>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						padding: '2rem 0',
						flexDirection: props.isMobile
							? 'column'
							: 'row',
					}}
				>
					<Box
						sx={{
							width: '100%',
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
						}}
					>
						<Typography
							sx={{
								fontWeight: 'bold',
							}}
						>
							{t(Translations.FOLLOW_FE)}
						</Typography>
						<Box
							sx={{
								paddingTop: '10px',

								display: 'flex',
								justifyContent: props.isMobile
									? 'space-evenly'
									: 'flex-start',
							}}
						>
							<Facebook
								sx={{
									marginRight: '20px',
									fontSize: '24px',
									height: '24px',
									cursor: 'pointer',
								}}
								onClick={() =>
									window.open(
										'https://www.facebook.com/finaejerique',
										'_blank'
									)
								}
							/>
							<Pinterest
								sx={{
									marginRight: '20px',
									fontSize: '29px',
									height: '24px',
									cursor: 'pointer',
								}}
								onClick={() =>
									window.open(
										'https://www.pinterest.es/finaejerique/',
										'_blank'
									)
								}
							/>
							<Instagram
								sx={{
									marginRight: '20px',
									fontSize: '20.5px',
									height: '24px',
									cursor: 'pointer',
								}}
								onClick={() =>
									window.open(
										'https://www.instagram.com/finaejeriquemoda/',
										'_blank'
									)
								}
							/>
						</Box>
					</Box>

					<Box
						sx={{
							width: '100%',
							marginTop: props.isMobile
								? '2rem'
								: 0,
						}}
					>
						<FooterForm />
					</Box>
				</Box>
				<Box
					sx={{
						width: '100%',
					}}
				>
					<FooterLegal />
				</Box>
				<Divider />
			</Box>
			<Box
				sx={{
					width: '100%',
					backgroundColor: '#968F8B',
					height: '45px',
				}}
			></Box>
		</Box>
	)
}
