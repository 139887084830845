import {
	Container,
	Typography,
	Grid,
	TextField,
} from '@mui/material'
import { useState } from 'react'
import {
	useNavigate,
	useParams,
} from 'react-router-dom'
import { IRoute } from '../../interfaces/route.interface'
import { BlackButton } from '../product/black-button'
import ContactUsBody from './contact-us-body.component'
import { Translations } from '../../translations/translations.enum'
import { useTranslation } from 'react-i18next'

export interface IContactUsProps extends IRoute {}

const ContactUs = (props: IContactUsProps) => {
	const { t } = useTranslation()
	return (
		<Grid
			container
			sx={{
				p: '5%',
			}}
		>
			<Grid
				item
				md={3}
				xs={12}
			>
				<Typography
					component='h1'
					variant='h5'
					fontFamily={'Bembo'}
					fontSize={'2rem'}
					sx={{
						mb: '1rem',
					}}
				>
					{t(Translations.CALL_US)}
				</Typography>
				<Typography
					component='h1'
					variant='h5'
					fontFamily={'Bembo'}
					fontSize={'1.25rem'}
					sx={{
						mb: '1.25rem',
					}}
				>
					+34 96 313 51 67
				</Typography>

				<Typography
					component='h1'
					variant='h5'
					fontFamily={'Bembo'}
					fontSize={'2rem'}
					sx={{
						mb: '1rem',
					}}
				>
					{t(Translations.CONTACT_US)}
				</Typography>
				<Typography
					component='h1'
					variant='h5'
					fontFamily={'Bembo'}
					fontSize={'1.25rem'}
					sx={{
						mb: '1.25rem',
					}}
				>
					hello@finaejerique.es
				</Typography>
				<Typography
					sx={{
						mt: '2rem',
					}}
				>
					{t(Translations.CONTACT_US_DESCRIPTION)}
				</Typography>
			</Grid>
			<Grid
				item
				md={9}
				xs={12}
			>
				<ContactUsBody
					isMobile={props.isMobile}
				/>
			</Grid>
		</Grid>
	)
}

export default ContactUs
