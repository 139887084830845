import { Close } from '@mui/icons-material'
import { Box, TextField, Typography } from '@mui/material'
import { useState, useEffect } from 'react'
import { IProduct, IProductVariant } from '../product'
import { useNavigate, useParams } from 'react-router-dom'
import { QuantityChanger } from '../quantity-changer/quantity-changer.component'
import { IRoute } from '../../interfaces/route.interface'
import { getPriceComponent } from '../../utils/price-component'

export interface ICartProductProps extends IRoute {
	productId: number
	variantId: number | undefined
	price: number
	quantity: number

	updateCart: (modelId: number, variantId: number, quantity: number) => void
	deleteCartProduct: (
		modelId: number,
		variantId: number | 'default' | undefined
	) => void
	setIsSomeProductOutOfStock: (value: boolean) => void
}

export function CartProduct(props: ICartProductProps) {
	const { language } = useParams()
	const [product, setProduct] = useState<IProduct | undefined>(undefined)
	const navigate = useNavigate()

	const [variant, setVariant] = useState<IProductVariant | undefined>(undefined)

	useEffect(() => {
		fetch(
			`${process.env.REACT_APP_CORE_API_URL}/model/${props.productId}?language=${language}`
		)
			.then((response) => response.json())
			.then((data) => setProduct(data))

		fetch(
			`${process.env.REACT_APP_CORE_API_URL}/variant/${props.variantId}?language=${language}`
		)
			.then((response) => response.json())
			.then((data) => setVariant(data))
	}, [language])

	const outOfStock = variant?.stock === 0

	if (outOfStock) {
		props.setIsSomeProductOutOfStock(true)
	}

	return (
		<Box
			sx={{
				display: 'flex',
				alignItems: 'center',
				padding: '20px 0',
				flexDirection: 'row',
				margin: '0 auto',
				width: '100%',
				position: 'relative',
				overflow: 'hidden',
			}}
		>
			<img
				height='175px'
				src={`${process.env.REACT_APP_IMAGE_URL}/${process.env.REACT_APP_CART_IMAGE_PATH}/${product?.images[0]}`}
				onClick={() => navigate(`/${language}/product/${props.productId}`)}
				style={{
					cursor: 'pointer',
				}}
			/>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-between',
					marginLeft: '20px',
					width: '100%',
				}}
			>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'flex-end',
						cursor: 'pointer', // Cambia el cursor al pasar por encima
						mb: '1.5rem',
					}}
					onClick={() =>
						props.deleteCartProduct(props.productId, props.variantId)
					}
				>
					<Close />
				</Box>
				<Typography
					variant='h5'
					fontFamily={'Antic'}
					fontWeight={600}
					sx={{
						cursor: 'pointer',
					}}
					onClick={() => navigate(`/${language}/product/${props.productId}`)}
				>
					{product?.name || ''}
				</Typography>
				<Typography
					variant='h6'
					fontFamily={'Antic'}
				>
					{variant?.size ? `Talla: ${variant?.size}` : ''}
				</Typography>
				{outOfStock && (
					<Typography
						variant='h6'
						fontFamily={'Antic'}
						color={'red'}
					>
						Out of stock
					</Typography>
				)}
				{props.variantId && (
					<QuantityChanger
						isMobile={props.isMobile}
						quantity={props.quantity}
						productId={props.productId}
						variantId={props.variantId}
						updateCart={props.updateCart}
					/>
				)}
			</Box>{' '}
			<Typography
				fontFamily={'Antic'}
				variant='h6'
				sx={{
					position: 'absolute', // Posicionamiento absoluto para Typography
					bottom: '10px', // Coloca el Typography al fondo del Box
					right: 0, // Alinea el Typography a la izquierda del Box
					m: 1, // Añade un margen para separarlo un poco de los bordes
				}}
			>
				{getPriceComponent(
					'1.25rem',
					product?.price || 0,
					product?.priceAfterDiscount
				)}
			</Typography>
		</Box>
	)
}
