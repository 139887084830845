import './App.css'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import { Header, WIPComponent } from './components'
import { ThemeProvider, useMediaQuery, useTheme } from '@mui/material'
import { Footer } from './components/footer/footer'
import './assets/fonts/avenir-book.css'
import './assets/fonts/avenir-black.css'
import './assets/fonts/bembo.css'
import { Home } from './components/home/home'
import customTheme from './theme'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { NotFound } from './components/not-found/not-found'
import { Category } from './components/category/category'
import { Product } from './components/product'
import ScrollToTop from './components/utils/scroll-to-top'
import { useEffect, useState } from 'react'
import { NoProductsFound } from './components/not-found/no-products-found'
import Cart from './components/cart/cart'
import { LanguageISOCode } from './enums/language-iso-code.enum'
import { Login } from './components/login/login'
import SignUp from './components/signup/signup'
import { ICart } from './interfaces/cart.interface'
import { OrganizationsPage } from './components/footer/pages/organizations'
import { PurchaseConditions } from './components/footer/pages/purchase-conditions'
import PrivacyPolicy from './components/footer/pages/privacy-policy'
import AddressList from './components/address/address-list'
import AddressForm from './components/address/address-form'
import Cookies from 'js-cookie'
import { Account } from './components/account/account.component'
import { emptyCart, fetchUpdatedCart, retrieveCart } from './utils/cart.util'
import { AccountRoutes } from './translations/translations.enum'
import { CardPaymentPage } from './components/payment/redsys-v2'
import { RedsysProcessingPayment } from './components/payment/processing-payment'
import ContactUs from './components/contact-us/contact-us.component'
import { ReturnsPage } from './components/footer/pages/returns'
import { OrderingAndShippingPage } from './components/footer/pages/order-and-shipping'
import { FeStoryPage } from './components/footer/pages/fe-story'
import { StoreLocatorPage } from './components/footer/pages/store-locator'
import { TermsAndConditionsPage } from './components/footer/pages/terms-and-conditions'
import CookiePolicy from './components/footer/pages/cookies-policy'
import FAQSPage from './components/footer/pages/faqs'
import { PasswordRecovery } from './components/password-recovery/password-recovery'
import PartnersPage from './components/partners/partners.page'
import { RedirectHandler } from './components/redirect-handler/redirect-handler'

function App() {
	const theme = useTheme()
	const isMobile = useMediaQuery(
		theme.breakpoints.down(
			parseInt(process.env.REACT_APP_MAX_MOBILE_WIDTH_PX || 'md')
		)
	)
	const [language, setLanguage] = useState<LanguageISOCode>(LanguageISOCode.es)
	const [isLogged, setIsLogged] = useState(!!Cookies.get('accessToken'))

	const [cart, setCart] = useState<ICart>(emptyCart())

	const deleteCartProduct = async (
		modelId: number,
		variantId: number | 'default' = 'default'
	) => {
		const cartToUpdate = { ...cart }
		delete cartToUpdate.products[modelId][variantId]

		await fetchUpdatedCart(cartToUpdate, setCart, isLogged)
	}

	const updateCart = async (
		modelId: number,
		quantity: number,
		variantId: number | 'default' = 'default'
	) => {
		const cartToUpdate = { ...cart }

		if (!cartToUpdate.products[modelId]) cartToUpdate.products[modelId] = {}
		cartToUpdate.products[modelId][variantId] = quantity

		await fetchUpdatedCart(cartToUpdate, setCart, isLogged)
	}

	useEffect(() => {
		const language: LanguageISOCode | null = localStorage.getItem(
			'language'
		) as LanguageISOCode
		if (language) {
			setLanguage(LanguageISOCode[language])
		}

		retrieveCart(setCart, isLogged)
	}, [])

	const appBarHeight = isMobile
		? `${process.env.REACT_APP_HEADER_HEIGHT_MOBILE_PX}px`
		: `${process.env.REACT_APP_HEADER_HEIGHT_DESKTOP_PX}px`

	return (
		<ThemeProvider theme={customTheme}>
			<Router>
				<ScrollToTop />
				<Header
					isMobile={isMobile}
					appBarHeight={appBarHeight}
					language={language}
					setLanguage={setLanguage}
					isLogged={isLogged}
					setIsLogged={setIsLogged}
					setCart={setCart}
				/>
				<Routes>
					<Route
						path='/:language/account'
						element={
							<Account
								isMobile={isMobile}
								route={AccountRoutes.ACCOUNT_OVERVIEW}
								setIsLogged={setIsLogged}
								setCart={setCart}
							/>
						}
					/>
					<Route
						path='/:language/account/addresses'
						element={
							<Account
								isMobile={isMobile}
								route={AccountRoutes.ADDRESS_BOOK}
								setIsLogged={setIsLogged}
								setCart={setCart}
							/>
						}
					/>
					<Route
						path='/:language/account/addresses/new'
						element={
							<Account
								isMobile={isMobile}
								route={AccountRoutes.ADDRESS_NEW}
								setIsLogged={setIsLogged}
								setCart={setCart}
							/>
						}
					/>
					<Route
						path='/:language/account/addresses/edit/:addressName'
						element={
							<Account
								isMobile={isMobile}
								route={AccountRoutes.ADDRESS_EDIT}
								setIsLogged={setIsLogged}
								setCart={setCart}
							/>
						}
					/>
					<Route
						path='/:language/account/orders'
						element={
							<Account
								isMobile={isMobile}
								route={AccountRoutes.ORDER_HISTORY}
								setIsLogged={setIsLogged}
								setCart={setCart}
							/>
						}
					/>
					<Route
						path='/:language/account/returns'
						element={
							<Account
								isMobile={isMobile}
								route={AccountRoutes.RETURNS}
								setIsLogged={setIsLogged}
								setCart={setCart}
							/>
						}
					/>
					<Route
						path='/:language/account/my-info'
						element={
							<Account
								isMobile={isMobile}
								route={AccountRoutes.MY_INFO}
								setIsLogged={setIsLogged}
								setCart={setCart}
							/>
						}
					/>
					<Route
						path='/:language/contact-us'
						element={<ContactUs isMobile={isMobile} />}
					/>
					<Route
						path='/:language/account/change-password'
						element={
							<Account
								isMobile={isMobile}
								route={AccountRoutes.CHANGE_PASSWORD}
								setIsLogged={setIsLogged}
								setCart={setCart}
							/>
						}
					/>
					<Route
						path='/:language/account/orders/:orderId'
						element={
							<Account
								isMobile={isMobile}
								route={AccountRoutes.ORDER_VIEW}
								setIsLogged={setIsLogged}
								setCart={setCart}
							/>
						}
					/>
					<Route
						path='/:language/account/returns/:orderId'
						element={
							<Account
								isMobile={isMobile}
								route={AccountRoutes.RETURN_VIEW}
								setIsLogged={setIsLogged}
								setCart={setCart}
							/>
						}
					/>
					<Route
						path='/:language/account/orders/:orderId/return'
						element={
							<Account
								isMobile={isMobile}
								route={AccountRoutes.ORDER_RETURN}
								setIsLogged={setIsLogged}
								setCart={setCart}
							/>
						}
					/>
					<Route
						path='/:language/new-address'
						element={<AddressForm isMobile={isMobile} />}
					/>
					<Route
						path='/:language/addresses'
						element={<AddressList isMobile={isMobile} />}
					/>
					<Route
						path='/:language/address/edit/:addressName'
						element={<AddressForm isMobile={isMobile} />}
					/>
					<Route
						path='/:language?'
						element={<Home isMobile={isMobile} />}
					/>
					<Route
						path='/:language/category/:id'
						element={<Category isMobile={isMobile} />}
					/>
					<Route
						path='/:language/product/:id'
						element={
							<Product
								isMobile={isMobile}
								updateCart={updateCart}
							/>
						}
					/>
					<Route
						path='/:language/search'
						element={<NoProductsFound isMobile={isMobile} />}
					/>
					<Route
						path='/:language/search/:query'
						element={
							// DONT remove div
							<div>
								<Category isMobile={isMobile} />
							</div>
						}
					/>
					<Route
						path='/:language/shopping-bag'
						element={
							<Cart
								isMobile={isMobile}
								cart={cart}
								deleteCartProduct={deleteCartProduct}
								setCart={setCart}
								setIsLogged={setIsLogged}
								updateCart={updateCart}
							/>
						}
					/>
					<Route
						path='/:language/shopping-bag/insite/:redsysId'
						element={<CardPaymentPage />}
					/>
					<Route
						path='/:language/processing-payment'
						element={<RedsysProcessingPayment />}
					/>
					<Route
						path='/:language/login'
						element={
							<Login
								isMobile={isMobile}
								setIsLogged={setIsLogged}
								setCart={setCart}
							/>
						}
					/>
					<Route
						path='/:language/password-recovery'
						element={<PasswordRecovery isMobile={isMobile} />}
					/>
					<Route
						path='/:language/organizations'
						element={<OrganizationsPage isMobile={isMobile} />}
					/>
					<Route
						path='/:language/purchase-conditions'
						element={<PurchaseConditions isMobile={isMobile} />}
					/>
					<Route
						path='/:language/privacy-policy'
						element={<PrivacyPolicy isMobile={isMobile} />}
					/>
					<Route
						path='/:language/cookie-policy'
						element={<CookiePolicy isMobile={isMobile} />}
					/>
					<Route
						path='/:language/faqs'
						element={<FAQSPage isMobile={isMobile} />}
					/>
					<Route
						path='/:language/returns'
						element={<ReturnsPage isMobile={isMobile} />}
					/>
					<Route
						path='/:language/ordering-and-shipping'
						element={<OrderingAndShippingPage isMobile={isMobile} />}
					/>
					<Route
						path='/:language/faqs'
						element={<WIPComponent />}
					/>
					<Route
						path='/:language/store-locator'
						element={<StoreLocatorPage isMobile={isMobile} />}
					/>
					<Route
						path='/:language/terms-and-conditions'
						element={<TermsAndConditionsPage isMobile={isMobile} />}
					/>
					<Route
						path='/:language/fe-story'
						element={<FeStoryPage isMobile={isMobile} />}
					/>
					<Route
						path='/:language/signup'
						element={
							<SignUp
								isMobile={isMobile}
								setIsLogged={setIsLogged}
								setCart={setCart}
							/>
						}
					/>
					<Route
						path='/:language/partners'
						element={<PartnersPage isMobile={isMobile} />}
					/>

					<Route
						path='/:language/:entityName'
						element={
							<RedirectHandler
								isMobile={isMobile}
								updateCart={updateCart}
							/>
						}
					/>

					<Route
						path='/:language/*'
						element={<NotFound isMobile={isMobile} />}
					/>
					<Route
						path='*'
						element={<NotFound isMobile={isMobile} />}
					/>
				</Routes>

				<Footer isMobile={isMobile} />
			</Router>
		</ThemeProvider>
	)
}

export default App
