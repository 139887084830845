import {
	Container,
	Typography,
	Grid,
	TextField,
	CircularProgress,
	Alert,
} from '@mui/material'
import { useState } from 'react'
import { IRoute } from '../../interfaces/route.interface'
import { BlackButton } from '../product/black-button'
import { RequestManager } from '../utils'
import { useTranslation } from 'react-i18next'
import {
	AddressTranslations,
	Translations,
} from '../../translations/translations.enum'

export interface IChangePasswordProps
	extends IRoute {}

const ContactUsBody = (
	props: IChangePasswordProps
) => {
	const { t } = useTranslation()

	const [formData, setFormData] = useState<any>({
		firstName: '',
		lastName: '',
		email: '',
		message: '',
	})
	const [errors, setErrors] = useState<any>({})
	const [successMessage, setSuccessMessage] =
		useState<string>('')
	const [errorMessage, setErrorMessage] =
		useState<string>('')

	const validateForm = () => {
		let tempErrors: any = {}

		if (
			formData.firstName.length === 0 ||
			formData.lastName.length === 0 ||
			formData.email.length === 0 ||
			formData.message.length === 0
		) {
			tempErrors.message =
				'All fields are required. Please fill them.'
		}

		setErrors(tempErrors)
		return Object.keys(tempErrors).length === 0
	}

	const handleSubmit = async (event: any) => {
		event.preventDefault()
		if (validateForm()) {
			setSuccessMessage('')
			setErrorMessage('')
			try {
				const response = await RequestManager(
					'POST',
					'email',
					false,
					{
						params: {
							firstName: formData.firstName,
							lastName: formData.lastName,
							email: formData.email,
							message: formData.message,
						},
					}
				)
				if (
					[200, 201].includes(response.status)
				) {
					setSuccessMessage(
						t(
							Translations.MESSAGE_SENT_SUCCESSFULLY
						)
					)
				} else {
					setErrorMessage(
						t(Translations.AN_ERROR_OCCURRED)
					)
				}
			} catch (error) {
				setErrorMessage(
					t(Translations.AN_ERROR_OCCURRED)
				)
			}
		}
	}

	const handleChange = (event: any) => {
		const { name, value, type, checked } =
			event.target

		setFormData({
			...formData,
			[name]:
				type === 'checkbox' ? checked : value,
		})
	}

	const handleKeyDown = (event: any) => {
		if (
			event.key === 'Enter' &&
			!event.shiftKey
		) {
			event.preventDefault()
			const { name, value } = event.target
			setFormData({
				...formData,
				[name]: value + '\n',
			})
		}
	}
	return (
		<Container
			component='main'
			maxWidth={props.isMobile ? 'xs' : 'md'}
			sx={{
				width: props.isMobile ? '100%' : '50%',
				fontFamily: 'Bembo',
				my: props.isMobile ? '2rem' : '0',
			}}
		>
			<Typography
				component='h1'
				variant='h5'
				fontFamily={'Bembo'}
				fontSize={'2rem'}
				sx={{
					mb: '2rem',
				}}
			>
				{t(Translations.CONTACT_US)}
			</Typography>
			<form
				onSubmit={handleSubmit}
				noValidate
			>
				<Grid
					container
					spacing={2}
				>
					<Grid
						item
						xs={12}
					>
						<TextField
							required
							fullWidth
							name='firstName'
							label={t(
								AddressTranslations.ADDRESS_FIRST_NAME
							)}
							type='text'
							id='firstName'
							value={formData.firstName}
							onChange={handleChange}
							error={!!errors.firstName}
							helperText={errors.firstName}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						gap={2}
					>
						<TextField
							required
							fullWidth
							name='lastName'
							label={t(
								AddressTranslations.ADDRESS_LAST_NAME
							)}
							type='text'
							id='lastName'
							value={formData.lastName}
							onChange={handleChange}
							error={!!errors.lastName}
							helperText={errors.lastName}
						/>
					</Grid>
					<Grid
						item
						xs={12}
					>
						<TextField
							required
							fullWidth
							name='email'
							label='Email'
							type='text'
							id='email'
							value={formData.email}
							onChange={handleChange}
							error={!!errors.email}
							helperText={errors.email}
						/>
					</Grid>
					<Grid
						item
						xs={12}
					>
						<TextField
							required
							fullWidth
							multiline
							minRows={3} // Ajusta esto según el tamaño mínimo deseado
							maxRows={10} // Ajusta el máximo de líneas que quieres que crezca automáticamente
							name='message'
							label={t(Translations.MESSAGE)}
							type='text'
							id='message'
							value={formData.message}
							onChange={handleChange}
							onKeyDown={handleKeyDown}
							error={!!errors.message}
							helperText={errors.message}
							sx={{
								'& .MuiInputBase-root': {
									height: 'auto', // Asegura que el contenedor interno se ajuste al tamaño del texto
								},
								'& .MuiOutlinedInput-input': {
									height: 'auto', // Elimina la altura fija para permitir el crecimiento
								},
							}}
						/>
					</Grid>

					{successMessage && (
						<Grid
							item
							xs={12}
						>
							<Alert severity='success'>
								{t(
									Translations.MESSAGE_SENT_SUCCESSFULLY
								)}
							</Alert>
						</Grid>
					)}
					{errorMessage && (
						<Grid
							item
							xs={12}
						>
							<Alert severity='error'>
								{t(
									Translations.AN_ERROR_OCCURRED
								)}
							</Alert>
						</Grid>
					)}
					<Grid
						item
						xs={12}
					>
						<BlackButton
							text={t(Translations.SEND_MESSAGE)}
						/>
					</Grid>
				</Grid>
			</form>
		</Container>
	)
}

export default ContactUsBody
