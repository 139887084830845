import {
	Container,
	Grid,
	List,
	ListItem,
	ListItemText,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material'
import { IRoute } from '../../../interfaces/route.interface'
import {
	LocalLaundryService as WashIcon,
	DoNotDisturb as NoWashIcon,
	Opacity as BleachIcon,
	Block as NoBleachIcon,
	Iron as IronIcon,
	DryCleaning as DryCleanIcon,
	NoPhotography as NoDryCleanIcon,
	CheckCircle as TumbleDryIcon,
	Cancel as NoTumbleDryIcon,
	ArrowDropDownCircle as LowTempDryIcon,
	RadioButtonChecked as NormalTempDryIcon,
} from '@mui/icons-material'
import { SizeChartTable } from './components/size-chart-table'
export default function FAQSPage(props: IRoute) {
	return (
		<Container>
			<Paper
				elevation={3}
				style={{
					padding: '4rem',
					margin: '20px 0',
				}}
			>
				<Typography
					variant='h5'
					sx={{
						mt: '2rem',
					}}
					gutterBottom
				>
					Envío y seguimiento del pedido
				</Typography>

				<Typography
					variant='h5'
					sx={{
						mt: '2rem',
					}}
					gutterBottom
				>
					¿Cuáles son los tipos de envío y los plazos de entrega de un pedido?
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					ENTREGA A DOMICILIO GRATUITA A PARTIR DE 50€
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					Los plazos de entrega van de 1 a 3 días laborables en la península (1
					o 2 días más en las Islas Baleares). No se realizan envíos a las Islas
					Canarias.
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					Los plazos de entrega son para los pedidos realizados de lunes a
					viernes antes de las 09.00h am. Después de esa hora, el plazo
					comenzará al siguiente día hábil. En periodos especiales como Black
					Friday o Rebajas, el plazo de envío puede ser superior al habitual.
				</Typography>

				<Typography
					variant='h5'
					sx={{
						mt: '2rem',
					}}
					gutterBottom
				>
					¿Dónde está mi pedido?
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					Consulta los detalles de tus compras y conoce su estado en todo
					momento desde Mi cuenta. Recibirás notificaciones por e-mail para que
					puedas hacer el seguimiento de forma fácil. No pierdas de vista
					nuestras comunicaciones.
				</Typography>

				<Typography
					variant='h5'
					sx={{
						mt: '2rem',
					}}
					gutterBottom
				>
					¿Puedo cambiar la dirección de entrega de un pedido?
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					Podemos realizar el cambio de dirección de un pedido mientras se
					encuentre en preparación en nuestros almacenes. Una vez está preparado
					para el envío o en manos del transportista, ya no podemos realizar
					cambios sobre el pedido. Consulta el estado en Mis pedidos y, si estás
					a tiempo, contacta con nuestro equipo de Atención al cliente.
				</Typography>

				<Typography
					variant='h5'
					sx={{
						mt: '2rem',
					}}
					gutterBottom
				>
					¿Puedo cancelar mi pedido?
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					Puedes solicitar a nuestro equipo de Atención al cliente la
					cancelación de tu pedido siempre que se encuentre en preparación en
					nuestro almacén. Si tu pedido ya no se puede cancelar, recuerda que
					tienes 30 días desde el envío para hacer cambios y devoluciones de
					forma fácil.
				</Typography>

				<Typography
					variant='h5'
					sx={{
						mt: '2rem',
					}}
					gutterBottom
				>
					¿Qué puedo hacer si no he recibido todos los artículos de mi compra?
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					En el caso de que alguno de los artículos de tu compra se haya agotado
					en el último momento y no te lo hayamos enviado, tendrás un e-mail con
					un aviso. Además, te reembolsaremos el importe de los artículos
					agotados y los gastos de envío en el mismo método de pago de la
					compra.
				</Typography>

				<Typography
					variant='h5'
					sx={{
						mt: '2rem',
					}}
					gutterBottom
				>
					¿Qué puedo hacer si he recibido algún artículo incorrecto o
					defectuoso?
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					Sentimos que alguno de los artículos no sea lo que esperabas. Haz la
					devolución desde Mi Cuenta indicando en el formulario de devolución
					que se trata de un artículo incorrecto o defectuoso. Te reembolsaremos
					el importe de los artículos más los gastos de envío en cuanto los
					recibamos en nuestros almacenes.
				</Typography>

				<Typography
					variant='h5'
					sx={{
						mt: '2rem',
					}}
					gutterBottom
				>
					Devoluciones, cambios y reembolsos
				</Typography>

				<Typography
					variant='h5'
					sx={{
						mt: '2rem',
					}}
					gutterBottom
				>
					¿Cómo puedo cambiar o devolver una compra online?
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					Las devoluciones son GRATUITAS. Dispones de 30 días tras la recepción
					de tu pedido para solicitar su devolución. Para ello, puedes solicitar
					la recogida gratuita a domicilio y enviaremos un transportista a
					recoger tu paquete:
				</Typography>
				<List>
					<ListItem>
						<ListItemText
							primary='1. Solicita la recogida a domicilio en finaejerique.es'
							secondary='Si estás registrado, accede al apartado Mi cuenta. A continuación, selecciona el pedido donde se encuentra el/los producto/s a devolver y una vez dentro, selecciónalo y dale a la opción Solicitar una devolución. Una vez realizados todos estos pasos procederemos a la recogida del paquete y si el producto se encuentra en buen estado se realizará el reembolso de la cantidad total del producto.'
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary='2. Prepara tu paquete'
							secondary='Empaqueta tus artículos de forma segura, en el paquete original si es posible, y pega la etiqueta de devolución en el paquete.'
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary='3. El transportista acudirá a tu domicilio para recoger tu paquete'
							secondary='Una vez realizados los pasos anteriores, el transportista acudirá a tu domicilio para proceder con la recogida.'
						/>
					</ListItem>
				</List>

				<Typography
					variant='h5'
					sx={{
						mt: '2rem',
					}}
					gutterBottom
				>
					¿Cómo puedo saber el estado de mi devolución?
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					Si hiciste una devolución tu paquete puede tardar varios días en
					llegar a nuestros almacenes. Recibirás un e-mail cuando realicemos el
					reembolso de las prendas o, en caso de un cambio, cuando enviemos la
					nueva talla que solicitaste.
				</Typography>

				<Typography
					variant='h5'
					sx={{
						mt: '2rem',
					}}
					gutterBottom
				>
					¿Cómo y cuándo recibiré mi reembolso?
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					Si se trata de una devolución, el reembolso se hará efectivo en el
					mismo método de pago utilizado en la compra y en un plazo de entre 3 y
					12 días laborables desde que recibimos tu paquete en nuestros
					almacenes. Recuerda que te reembolsaremos el importe de las prendas,
					no de los gastos de envío (solo si la prenda estaba defectuosa o era
					incorrecta). Si tu compra superó los 50€, no reembolsaremos los gastos
					de envío dado que el envío fue gratuito.
				</Typography>

				<Typography
					variant='h5'
					sx={{
						mt: '2rem',
					}}
					gutterBottom
				>
					¿Cuáles son las condiciones de devolución?
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					Los artículos deben devolverse en el mismo estado que se recibieron.
					Los artículos que hayas arreglado o estén personalizados tampoco se
					podrán cambiar ni devolver porque están hechos exclusivamente para ti
					y no se podrán poner de nuevo a la venta.
				</Typography>

				<Typography
					variant='h5'
					sx={{
						mt: '2rem',
					}}
					gutterBottom
				>
					Pagos, promociones y tarjetas regalo
				</Typography>

				<Typography
					variant='h5'
					sx={{
						mt: '2rem',
					}}
					gutterBottom
				>
					¿Cómo puedo pagar mis compras online?
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					Puedes utilizar los siguientes medios de pago: Visa, Visa Electron,
					Mastercard, Maestro, JCB, UPAY y Bizum.
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					En el caso de que el banco rechace tu tarjeta comprueba que:
				</Typography>
				<List>
					<ListItem>
						<ListItemText primary='La tarjeta no esté caducada.' />
					</ListItem>
					<ListItem>
						<ListItemText primary='Que tu tarjeta no exceda la fecha de validez.' />
					</ListItem>
					<ListItem>
						<ListItemText primary='Puede que se haya alcanzado el límite de la tarjeta. Consulta con tu banco que la tarjeta no haya excedido el importe permitido para efectuar compras.' />
					</ListItem>
					<ListItem>
						<ListItemText primary='Comprueba que has rellenado correctamente todos los campos necesarios.' />
					</ListItem>
				</List>

				<Typography
					variant='h5'
					sx={{
						mt: '2rem',
					}}
					gutterBottom
				>
					¿Puedo obtener una factura a nombre de mi empresa?
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					Sí. Solamente tienes que marcar la opción de «empresa» en datos
					personales y cubrir los datos fiscales que te pedimos.
				</Typography>

				<Typography
					variant='h5'
					sx={{
						mt: '2rem',
					}}
					gutterBottom
				>
					Tengo un código promocional, ¿cómo lo utilizo?
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					Antes de finalizar la compra, encuentra el espacio código promocional
					donde podrás añadir tu código (solo uno por compra). Si no se aplica
					ningún descuento, revisa las condiciones de la promoción o su fecha de
					validez. Puede que el código haya caducado o que los artículos de tu
					compra no entren en la promoción. Verás el importe acumulado que
					puedes descontar antes de finalizar tu compra.
				</Typography>

				<Typography
					variant='h5'
					sx={{
						mt: '2rem',
					}}
					gutterBottom
				>
					Información de tallas y productos
				</Typography>

				<Typography
					variant='h5'
					sx={{
						mt: '2rem',
					}}
					gutterBottom
				>
					¿Cómo cuido mis prendas?
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					Instrucciones específicas sobre el cuidado de las prendas se
					encuentran en la etiqueta de cada artículo. Sigue estas indicaciones
					para mantener tus prendas en buen estado.
				</Typography>

				<Typography
					variant='h5'
					gutterBottom
				>
					Instrucciones de Cuidado
				</Typography>

				<Typography
					variant='h5'
					gutterBottom
				>
					Lavado
				</Typography>
				<Grid
					container
					spacing={3}
				>
					<Grid
						item
						xs={12}
						sm={6}
						md={3}
					>
						<WashIcon style={{ fontSize: 50 }} />
						<Typography variant='body1'>
							Lavar a máquina. El número (30º C, 40º C, 60º C, 95º C) indica la
							temperatura máxima de lavado.
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						sm={6}
						md={3}
					>
						<WashIcon style={{ fontSize: 50 }} />
						<Typography variant='body1'>
							Lavar a máquina con programa normal y centrifugado corto. El
							número (30º C, 40º C, 60º C, 95º C) indica la temperatura máxima
							de lavado.
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						sm={6}
						md={3}
					>
						<WashIcon style={{ fontSize: 50 }} />
						<Typography variant='body1'>
							Lavar a mano máximo 30º C. No frotar ni escurrir.
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						sm={6}
						md={3}
					>
						<NoWashIcon style={{ fontSize: 50 }} />
						<Typography variant='body1'>No lavar.</Typography>
					</Grid>
				</Grid>

				<Typography
					variant='h5'
					gutterBottom
				>
					Uso de lejía
				</Typography>
				<Grid
					container
					spacing={3}
				>
					<Grid
						item
						xs={12}
						sm={6}
						md={3}
					>
						<BleachIcon style={{ fontSize: 50 }} />
						<Typography variant='body1'>Se puede usar lejía.</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						sm={6}
						md={3}
					>
						<NoBleachIcon style={{ fontSize: 50 }} />
						<Typography variant='body1'>No se puede usar lejía.</Typography>
					</Grid>
				</Grid>

				<Typography
					variant='h5'
					gutterBottom
				>
					Planchado
				</Typography>
				<Grid
					container
					spacing={3}
				>
					<Grid
						item
						xs={12}
						sm={6}
						md={3}
					>
						<IronIcon style={{ fontSize: 50 }} />
						<Typography variant='body1'>
							Planchado a temperatura alta: máximo 200º C. Algodón, lino o
							viscosa.
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						sm={6}
						md={3}
					>
						<IronIcon style={{ fontSize: 50 }} />
						<Typography variant='body1'>
							Planchado a temperatura media: máximo 150º C. Lana y mezclas de
							poliéster.
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						sm={6}
						md={3}
					>
						<IronIcon style={{ fontSize: 50 }} />
						<Typography variant='body1'>
							Planchado a temperatura baja: máximo 110º C. Lana y mezclas de
							poliéster. Seda natural, rayón, acetato o acrílico.
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						sm={6}
						md={3}
					>
						<NoWashIcon style={{ fontSize: 50 }} />
						<Typography variant='body1'>No planchar.</Typography>
					</Grid>
				</Grid>

				<Typography
					variant='h5'
					gutterBottom
				>
					Lavado en seco
				</Typography>
				<Grid
					container
					spacing={3}
				>
					<Grid
						item
						xs={12}
						sm={6}
						md={3}
					>
						<DryCleanIcon style={{ fontSize: 50 }} />
						<Typography variant='body1'>
							Las letras (A, F, P) contenidas en los círculos indican el tipo de
							producto adecuado para cada prenda (información útil para los
							especialistas en tintorería).
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						sm={6}
						md={3}
					>
						<NoDryCleanIcon style={{ fontSize: 50 }} />
						<Typography variant='body1'>No se puede lavar en seco.</Typography>
					</Grid>
				</Grid>

				<Typography
					variant='h5'
					gutterBottom
				>
					Secado
				</Typography>
				<Grid
					container
					spacing={3}
				>
					<Grid
						item
						xs={12}
						sm={6}
						md={3}
					>
						<TumbleDryIcon style={{ fontSize: 50 }} />
						<Typography variant='body1'>Se puede usar secadora.</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						sm={6}
						md={3}
					>
						<NoTumbleDryIcon style={{ fontSize: 50 }} />
						<Typography variant='body1'>No se puede usar secadora.</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						sm={6}
						md={3}
					>
						<LowTempDryIcon style={{ fontSize: 50 }} />
						<Typography variant='body1'>
							Secar en secadora a baja temperatura.
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						sm={6}
						md={3}
					>
						<NormalTempDryIcon style={{ fontSize: 50 }} />
						<Typography variant='body1'>
							Secar en secadora a temperatura normal.
						</Typography>
					</Grid>
				</Grid>

				<Typography
					variant='h5'
					sx={{
						mt: '2rem',
					}}
					gutterBottom
				>
					¿Cuál es mi talla?
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					Utiliza la Guía de tallas: verás las medidas que corresponden a cada
					talla para que puedas medirte y elegir cuál se adapta mejor a tu
					hijo/a.
				</Typography>

				<SizeChartTable />

				<Typography
					variant='h5'
					sx={{
						mt: '2rem',
					}}
					gutterBottom
				>
					Datos personales y comunicaciones
				</Typography>

				<Typography
					variant='h5'
					sx={{
						mt: '2rem',
					}}
					gutterBottom
				>
					He olvidado mi contraseña, ¿cómo puedo recuperarla?
				</Typography>
				<Typography
					variant='body1'
					paragraph
				>
					Cuando vayas a iniciar sesión en tu cuenta, verás un enlace para
					cambiar tu contraseña si la has olvidado. Luego, tendrás que añadir el
					e-mail de tu cuenta para recibir un mensaje con los pasos a seguir.
					¡Es muy sencillo!
				</Typography>
			</Paper>
		</Container>
	)
}
