import {
	Box,
	Link,
	Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Translations } from '../../translations/translations.enum'

export function CartHelp() {
	const { t } = useTranslation()

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: '10px',
				border: '1px solid #000',
				padding: '30px 20px',
				height: 'fit-content',
				marginTop: '30px',
			}}
		>
			<Typography
				variant='h4'
				fontFamily={'Bembo'}
				textAlign={'center'}
			>
				{t(Translations.NEED_HELP_ORDER)}
			</Typography>
			<Link
				href='mailto:hello@finaejerique.es'
				fontFamily={'Bembo'}
				textAlign={'center'}
			>
				hello@finaejerique.es
			</Link>
			<Link
				href='tel:+34963135167'
				fontFamily={'Bembo'}
				textAlign={'center'}
			>
				+34 96 313 51 67
			</Link>
		</Box>
	)
}
