import { Box, CircularProgress, Grid } from '@mui/material'
import CategoryHeader from '../../assets/images/category-header.jpg'
import { CategoryTitle } from './title'
import { CategoryDescription } from './description'
import { CategoryNavigationBar } from './navigation-bar'
import CategoryFilter from './filter'
import { IRoute } from '../../interfaces/route.interface'
import { CategoryGridItem } from './grid-item'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useParams, useSearchParams } from 'react-router-dom'
import { LazyImage } from '../lazy-image/lazy-image'
import searchMeiliSearch from '../search/meilisearch'
import { Translations } from '../../translations/translations.enum'
import { useTranslation } from 'react-i18next'
import CategoryMobileFilter from './mobile/category-filter.mobile'
import { Close } from '@mui/icons-material'
import { handleGATagViewItemList } from '../utils/google-analytics'
import { handleMetaTagViewItemList } from '../utils/meta-business'
import { NoProductsFound } from '../not-found/no-products-found'
import { getEntityEID } from '../utils/base64'

export interface CategoryItem {
	id: number
	externalReference: string
	tax: number
	name: string
	descriptionShort?: string
	metaTitle?: string
	metaDescription?: string
	metaKeywords?: string
	images: string[]
	price?: number
	priceAfterDiscount?: number
}

export interface ICategory {
	id: number
	name: string
	description?: string
	active: boolean
	children: ICategory[]
	externalReference: string
	sort?: number
}

export interface ICategoryProperties extends IRoute {
	timestamp?: string
}

export const Category = (props: ICategoryProperties) => {
	const { id, language, query } = useParams()
	const [searchResults] = useSearchParams()
	const { t } = useTranslation()
	const [hasMoreData, setHasMoreData] = useState(true)
	const categoryId = id ? Number(id) : getEntityEID(searchResults)

	const [data, setData] = useState<CategoryItem[]>([])
	const [category, setCategory] = useState<ICategory>()
	const [page, setPage] = useState(1)
	const [color, setColor] = useState<Record<string, boolean>>({})
	const [size, setSize] = useState<Record<string, boolean>>({})
	const pageSize = 12

	const fetchData = async (init?: boolean) => {
		const pageNumber = init ? 1 : page

		const selectedColors = Object.keys(color).reduce((acc: string[], key) => {
			if (color[key]) {
				acc.push(key)
			}
			return acc
		}, [])

		const selectedSizes = Object.keys(size).reduce((acc: string[], key) => {
			if (size[key]) {
				acc.push(key)
			}
			return acc
		}, [])

		const colorMap = selectedColors.map(
			(item: string) => `color=${item.replace('#', '%23')}`
		)
		const colorFilters = colorMap.join('&')

		const sizeMap = selectedSizes.map((item) => `size=${item}`)
		const sizeFilters = sizeMap.join('&')

		const hasColorParams = colorFilters ? '&' : ''
		const hasSizeParams = sizeFilters ? '&' : ''

		const response = await fetch(
			`${process.env.REACT_APP_CORE_API_URL}/category/${categoryId}/models?pageSize=${pageSize}&pageNumber=${pageNumber}&language=${language}${hasColorParams}${colorFilters}${hasSizeParams}${sizeFilters}`
		)
		const result = await response.json()
		const currentData = init ? [...result] : [...data, ...result]

		setPage(pageNumber + 1)
		setData(currentData)
		if (result.length < pageSize) {
			setHasMoreData(false)
		}
	}

	const getCategoryData = async () => {
		const response = await fetch(
			`${process.env.REACT_APP_CORE_API_URL}/category/${categoryId}?language=${language}`
		)
		const result = await response.json()
		setCategory(result)
	}

	const handleSearch = async () => {
		try {
			const searchResults = await searchMeiliSearch(query || '', language)

			setData(searchResults.hits || []) // Asumiendo que quieres los hits de los resultados
			setPage(0)
		} catch (error) {
			console.error('Error fetching search results:', error)
		}
	}

	useEffect(() => {
		if (query) {
			handleSearch()
		} else {
			setHasMoreData(true)
			setData([])
			setPage(1)
			getCategoryData()
			fetchData(true)
		}
	}, [id, categoryId, language, color, size, query])
	const aspectRatioPadding = `${100 / 3.26}%`

	useEffect(() => {
		if (category) {
			handleGATagViewItemList(category)
			handleMetaTagViewItemList(category)
		}
	}, [id, categoryId])

	return (
		<Box
			width={'100%'}
			paddingBottom={'80px'}
			key={'category'}
		>
			{category ? (
				<>
					<LazyImage
						imageSrc={CategoryHeader}
						width='100%'
						height={aspectRatioPadding}
						loadPlaceholderSize='10%'
					/>

					<Box
						width={'100%'}
						display={'flex'}
						justifyContent={'center'}
						alignItems={'center'}
						margin={'27px auto 10px'}
						flexDirection={'column'}
						textAlign={'center'}
					>
						<CategoryTitle title={category.name} />
					</Box>

					{category.children?.length > 0 ? (
						<CategoryNavigationBar
							{...props}
							children={category.children}
						/>
					) : null}

					<Box
						display={'flex'}
						flexDirection={'row'}
						alignItems={'center'}
					>
						{props.isMobile ? (
							<CategoryMobileFilter
								setColor={setColor}
								setSize={setSize}
								colors={color}
								sizes={size}
							/>
						) : (
							<>
								<CategoryFilter
									by={Translations.COLOR}
									setter={setColor}
									values={color}
									isMobile={props.isMobile}
								/>
								<CategoryFilter
									by={Translations.SIZE}
									setter={setSize}
									values={size}
									isMobile={props.isMobile}
								/>
							</>
						)}
						{(Object.keys(color).length > 0 ||
							Object.keys(size).length > 0) && (
							<Close
								onClick={() => {
									setColor({})
									setSize({})
								}}
							/>
						)}
					</Box>

					<InfiniteScroll
						key={categoryId}
						dataLength={data?.length || 0}
						next={fetchData}
						hasMore={hasMoreData} // cambia esto dependiendo de si hay más elementos para cargar
						loader={<h4>{t(Translations.LOADING)}...</h4>}
					>
						<Grid
							container
							sx={{
								marginTop: '10px',
							}}
						>
							{data?.map((item, index) => (
								<CategoryGridItem
									key={item.id}
									product={item}
									isMobile={props.isMobile}
								/>
							))}
						</Grid>
					</InfiniteScroll>
				</>
			) : query && data?.length ? (
				<InfiniteScroll
					key={categoryId}
					dataLength={data?.length || 0}
					next={fetchData}
					hasMore={false} // cambia esto dependiendo de si hay más elementos para cargar
					loader={<h4>{t(Translations.LOADING)}...</h4>}
				>
					<Grid
						container
						sx={{
							marginTop: '10px',
						}}
					>
						{data?.map((item, index) => (
							<CategoryGridItem
								key={item.id}
								product={item}
								isMobile={props.isMobile}
								hidePrice={false}
							/>
						))}
					</Grid>
				</InfiniteScroll>
			) : data?.length === 0 ? (
				<NoProductsFound isMobile={props.isMobile} />
			) : (
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						height: '40vh',
					}}
				>
					<CircularProgress color='inherit' />
				</Box>
			)}
		</Box>
	)
}
