import { Box } from '@mui/material'
import { IRoute } from '../../interfaces/route.interface'
import { MobileCarousel } from './mobile/carousel'
import { DesktopProductBody } from './desktop/product-body'
import { MobileOtherProductsCarousel } from './mobile/other-products-carousel'
import { MobileOtherProductsTitle } from './mobile/other-products-title'
import { useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { ModelFeatureType } from 'common'
import { t } from 'i18next'
import { Translations } from '../../translations/translations.enum'
import { useTranslation } from 'react-i18next'
import { ProductQuantity } from '../../interfaces/cart.interface'
import { decodeBase64, getEntityEID } from '../utils/base64'

export interface IProductFeature {
	title?: string
	description?: string
}

export interface IProductVariant {
	variantId: number
	color: string
	size: string
	main: boolean
	stock: number
}

export interface IProductVersion {
	modelId: number
	color: string
}

export interface IProduct {
	id: number
	externalReference: string
	tax: number
	name: string
	description?: string
	metaTitle?: string
	metaDescription?: string
	metaKeywords?: string
	images: string[]
	features: Record<ModelFeatureType, IProductFeature>
	variants?: IProductVariant[]
	versions?: IProductVersion[]
	price?: number
	relatedModels: number[]
	lookModels: number[]
	priceAfterDiscount?: number
}

export interface IProductProps extends IRoute {
	updateCart: (
		modelId: number,
		product: ProductQuantity,
		variantId: number | 'default'
	) => void
}

export const Product = (props: IProductProps) => {
	const { t } = useTranslation()
	const [searchParams] = useSearchParams()
	const { id, language } = useParams()
	const [product, setProduct] = useState<IProduct | undefined>(undefined)
	const [productId, setProductId] = useState<number | null>(
		getEntityEID(searchParams) || Number(id)
	)

	useEffect(() => {
		fetch(
			`${process.env.REACT_APP_CORE_API_URL}/model/${productId}?language=${language}`
		)
			.then((response) => response.json())
			.then((data) => setProduct(data))
		console.log({
			productId,
			id,
			searchParams,
		})
		setProductId(getEntityEID(searchParams) || Number(id))
	}, [productId, language, searchParams])

	return (
		<Box>
			{props.isMobile && product && (
				<MobileCarousel images={product?.images || []} />
			)}
			{/* <MobileProductBody /> */}
			<DesktopProductBody
				{...props}
				product={product}
			/>
			{!props.isMobile && <Box margin={'140px 0'}></Box>}
			<MobileOtherProductsTitle>
				{t(Translations.SIMILAR_PRODUCTS)}
			</MobileOtherProductsTitle>
			{product?.relatedModels && (
				<MobileOtherProductsCarousel
					{...props}
					relatedModels={product.relatedModels}
				/>
			)}
			<MobileOtherProductsTitle>
				{t(Translations.YOU_MAY_ALSO_LIKE)}
			</MobileOtherProductsTitle>
			{product?.lookModels && (
				<MobileOtherProductsCarousel
					{...props}
					relatedModels={product.lookModels}
				/>
			)}
		</Box>
	)
}
