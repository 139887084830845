import {
	Container,
	List,
	ListItem,
	ListItemText,
	Paper,
	Typography,
} from '@mui/material'
import { IRoute } from '../../../interfaces/route.interface'

export default function CookiePolicy(
	props: IRoute
) {
	return (
		<Container>
			<Paper
				elevation={3}
				style={{
					padding: '4rem',
					margin: '20px 0',
				}}
			>
				<Typography
					variant='h4'
					sx={{
						fontSize: '1.75rem',
					}}
					gutterBottom
				>
					Política de Cookies
				</Typography>

				<Typography
					variant='body1'
					sx={{
						fontSize: '0.95rem',
					}}
					paragraph
				>
					En la web GLOBOTEX, S.L. informa acerca
					del uso de las cookies en su página web:
					<a href='https://finaejerique.es/'>
						https://finaejerique.es/
					</a>
					.
				</Typography>

				<Typography
					variant='h5'
					sx={{
						fontSize: '1rem',
						fontWeight: 'bold',
					}}
					gutterBottom
				>
					¿QUÉ SON LAS COOKIES?
				</Typography>
				<Typography
					variant='body1'
					sx={{
						fontSize: '0.95rem',
					}}
					paragraph
				>
					Las cookies son archivos que se pueden
					descargar en su equipo a través de las
					páginas web. Son herramientas que tienen
					un papel esencial para la prestación de
					numerosos servicios de la sociedad de la
					información. Entre otros, permiten a una
					página web almacenar y recuperar
					información sobre los hábitos de
					navegación de un usuario o de su equipo
					y, dependiendo de la información
					obtenida, se pueden utilizar para
					reconocer al usuario y mejorar el
					servicio ofrecido.
				</Typography>

				<Typography
					variant='h5'
					sx={{
						fontSize: '1rem',
						fontWeight: 'bold',
					}}
					gutterBottom
				>
					TIPOS DE COOKIES
				</Typography>
				<Typography
					variant='body1'
					sx={{
						fontSize: '0.95rem',
					}}
					paragraph
				>
					Según quien sea la entidad que gestione
					el dominio desde donde se envían las
					cookies y trate los datos que se
					obtengan se pueden distinguir dos tipos:
				</Typography>
				<List>
					<ListItem>
						<ListItemText
							primary='Cookies propias'
							secondary='Aquéllas que se envían al equipo terminal del usuario desde un equipo o dominio gestionado por el propio editor y desde el que se presta el servicio solicitado por el usuario.'
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary='Cookies de terceros'
							secondary='Aquéllas que se envían al equipo terminal del usuario desde un equipo o dominio que no es gestionado por el editor, sino por otra entidad que trata los datos obtenidos través de las cookies.'
						/>
					</ListItem>
				</List>

				<Typography
					variant='body1'
					sx={{
						fontSize: '0.95rem',
					}}
					paragraph
				>
					Existe también una segunda clasificación
					según el plazo de tiempo que permanecen
					almacenadas en el navegador del cliente,
					pudiendo tratarse de:
				</Typography>
				<List>
					<ListItem>
						<ListItemText
							primary='Cookies de sesión'
							secondary='Diseñadas para recabar y almacenar datos mientras el usuario accede a una página web.'
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary='Cookies persistentes'
							secondary='Los datos siguen almacenados en el terminal y pueden ser accedidos y tratados durante un periodo definido por el responsable de la cookie, y que puede ir de unos minutos a varios años.'
						/>
					</ListItem>
				</List>

				<Typography
					variant='body1'
					sx={{
						fontSize: '0.95rem',
					}}
					paragraph
				>
					Por último, existe otra clasificación
					con cinco tipos de cookies según la
					finalidad para la que se traten los
					datos obtenidos:
				</Typography>
				<List>
					<ListItem>
						<ListItemText
							primary='Cookies técnicas'
							secondary='Permiten al usuario la navegación a través de una página web, plataforma o aplicación y la utilización de las diferentes opciones o servicios que en ella existan.'
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary='Cookies de personalización'
							secondary='Permiten al usuario acceder al servicio con algunas características de carácter general predefinidas en función de una serie de criterios.'
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary='Cookies de análisis'
							secondary='Permiten el seguimiento y análisis del comportamiento de los usuarios de los sitios web.'
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary='Cookies publicitarias'
							secondary='Permiten la gestión de los espacios publicitarios.'
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary='Cookies de publicidad comportamental'
							secondary='Almacenan información del comportamiento de los usuarios obtenida a través de la observación continuada de sus hábitos de navegación.'
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary='Cookies de redes sociales externas'
							secondary='Se utilizan para que los visitantes puedan interactuar con el contenido de diferentes plataformas sociales.'
						/>
					</ListItem>
				</List>

				<Typography
					variant='h5'
					sx={{
						fontSize: '1rem',
						fontWeight: 'bold',
					}}
					gutterBottom
				>
					DESACTIVACIÓN Y ELIMINACIÓN DE COOKIES
				</Typography>
				<Typography
					variant='body1'
					sx={{
						fontSize: '0.95rem',
					}}
					paragraph
				>
					Tienes la opción de permitir, bloquear o
					eliminar las cookies instaladas en tu
					equipo mediante la configuración de las
					opciones del navegador instalado en su
					equipo. Al desactivar cookies, algunos
					de los servicios disponibles podrían
					dejar de estar operativos. La forma de
					deshabilitar las cookies es diferente
					para cada navegador, pero normalmente
					puede hacerse desde el menú Herramientas
					u Opciones. También puede consultarse el
					menú de Ayuda del navegador, donde
					puedes encontrar instrucciones. El
					usuario podrá en cualquier momento
					elegir qué cookies quiere que funcionen
					en este sitio web.
				</Typography>

				<Typography
					variant='body1'
					sx={{
						fontSize: '0.95rem',
					}}
					paragraph
				>
					Puede usted permitir, bloquear o
					eliminar las cookies instaladas en su
					equipo mediante la configuración de las
					opciones del navegador instalado en su
					ordenador:
				</Typography>
				<List>
					<ListItem>
						<ListItemText
							primary='Microsoft Internet Explorer o Microsoft Edge'
							secondary={
								<a href='http://windows.microsoft.com/es-es/windows-vista/Block-or-allow-cookies'>
									http://windows.microsoft.com/es-es/windows-vista/Block-or-allow-cookies
								</a>
							}
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary='Mozilla Firefox'
							secondary={
								<a href='http://support.mozilla.org/es/kb/impedir-que-los-sitios-web-guarden-sus-preferencia'>
									http://support.mozilla.org/es/kb/impedir-que-los-sitios-web-guarden-sus-preferencia
								</a>
							}
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary='Chrome'
							secondary={
								<a href='https://support.google.com/accounts/answer/61416?hl=es'>
									https://support.google.com/accounts/answer/61416?hl=es
								</a>
							}
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary='Safari'
							secondary={
								<a href='http://safari.helpmax.net/es/privacidad-y-seguridad/como-gestionar-las-cookies/'>
									http://safari.helpmax.net/es/privacidad-y-seguridad/como-gestionar-las-cookies/
								</a>
							}
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary='Opera'
							secondary={
								<a href='http://help.opera.com/Linux/10.60/es-ES/cookies.html'>
									http://help.opera.com/Linux/10.60/es-ES/cookies.html
								</a>
							}
						/>
					</ListItem>
				</List>

				<Typography
					variant='body1'
					sx={{
						fontSize: '0.95rem',
					}}
					paragraph
				>
					Además, también puede gestionar el
					almacén de cookies en su navegador a
					través de herramientas como las
					siguientes:
				</Typography>
				<List>
					<ListItem>
						<ListItemText
							primary='Ghostery'
							secondary={
								<a href='http://www.ghostery.com/'>
									http://www.ghostery.com/
								</a>
							}
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary='Your online choices'
							secondary={
								<a href='http://www.youronlinechoices.com/es/'>
									http://www.youronlinechoices.com/es/
								</a>
							}
						/>
					</ListItem>
				</List>

				<Typography
					variant='h5'
					sx={{
						fontSize: '1rem',
						fontWeight: 'bold',
					}}
					gutterBottom
				>
					Aceptación de la Política de cookies
				</Typography>
				<Typography
					variant='body1'
					sx={{
						fontSize: '0.95rem',
					}}
					paragraph
				>
					<a href='https://finaejerique.es/'>
						https://finaejerique.es/
					</a>{' '}
					muestra información sobre su Política de
					cookies en la parte inferior o superior
					de cualquier página del portal con cada
					inicio de sesión con el objeto de que
					usted sea consciente.
				</Typography>

				<Typography
					variant='body1'
					sx={{
						fontSize: '0.95rem',
					}}
					paragraph
				>
					Ante esta información es posible llevar
					a cabo las siguientes acciones:
				</Typography>
				<List>
					<ListItem>
						<ListItemText
							primary='Aceptar cookies'
							secondary='No se volverá a visualizar este aviso al acceder a cualquier página del portal durante la presente sesión.'
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary='Modificar su configuración'
							secondary='Podrá obtener más información sobre qué son las cookies, conocer la Política de cookies de https://finaejerique.es/ y modificar la configuración de su navegador.'
						/>
					</ListItem>
				</List>
			</Paper>
		</Container>
	)
}
