import { Box, Typography } from '@mui/material'
import { useState } from 'react'
import { CategoryItem } from './category'
import { Link, useParams } from 'react-router-dom'
import { LazyImage } from '../lazy-image/lazy-image'
import { toTitleCase } from '../utils'
import { getPriceComponent } from '../../utils/price-component'
import { handleGATagSelectItem } from '../utils/google-analytics'
import { handleMetaTagSelectItem } from '../utils/meta-business'
import { getUrlParamWithEID, textoToSlug } from '../utils/base64'
export interface ICategoryProduct {
	productRecommendation?: boolean
	isMobile: boolean
	product?: CategoryItem
	hidePrice?: boolean
}

export const CategoryProduct = (props: ICategoryProduct) => {
	const { language } = useParams()
	const [hover, setHover] = useState(false)
	const images = props.product?.images
	const image = images && images[0]
	const imageHover = images && images[1]
	const [loaded, setLoaded] = useState(false)

	const productUrl = `/${language}/${getUrlParamWithEID(
		textoToSlug(props.product?.name || ''),
		'product',
		props.product?.id
	)}`

	return (
		<Box
			width='100%'
			height='fit-content'
			onMouseEnter={() => setHover(true)}
			onMouseLeave={() => setHover(false)}
		>
			<Link
				to={productUrl}
				style={{
					textDecoration: 'none',
					color: '#000',
				}}
				onClick={() => {
					if (props.product) {
						handleGATagSelectItem(props.product)
						handleMetaTagSelectItem(props.product)
					}
				}}
			>
				{hover && !props.isMobile && !!imageHover ? (
					<LazyImage
						imageSrc={`${process.env.REACT_APP_IMAGE_URL}/${process.env.REACT_APP_CATEGORY_PRODUCT_IMAGE_PATH}/${imageHover}`}
						loadPlaceholderSize='20%'
						height='124%'
					></LazyImage>
				) : (
					<LazyImage
						imageSrc={`${process.env.REACT_APP_IMAGE_URL}/${process.env.REACT_APP_CATEGORY_PRODUCT_IMAGE_PATH}/${image}`}
						loadPlaceholderSize='20%'
						height='124%'
					></LazyImage>
				)}
				<Box
					flexDirection={'column'}
					display={'flex'}
					sx={{
						fontStyle: 'normal',
						fontSize: '0.875rem',
						fontFamily: 'Avenir',
						fontWeight: 400,
						listStyle: 'none',
						lineHeight: 'inherit',
						letterSpacing: '0.075em',
						cursor: 'pointer',
						color: '#000',
						webkitFontSmoothing: 'antialiased',
						boxSizing: 'inherit',
						margin: 0,
						padding: 0,
						background: ' #fff',
						textAlign: 'left',
						minHeight: '120px',
						paddingTop: '8px',
						paddingLeft: '1.07143rem',
						paddinRight: '1.71429rem',
						position: 'relative',
						width: 'auto',
					}}
				>
					<Box
						sx={{
							WebkitTextSizeAdjust: '100%',
						}}
					>
						<Typography>{toTitleCase(props.product?.name || '')}</Typography>
						<Typography
							sx={{
								WebkitTextSizeAdjust: '100%',
								'--swiper-theme-color': '#007aff',
								'--swiper-navigation-size': '44px',
								'--scrollbarWidth': '0px',
								listStyle: 'none',
								color: 'inherit',
								textAlign: 'left',
								WebkitFontSmoothing: 'antialiased',
								boxSizing: 'inherit',
								fontWeight: 'normal',
								textRendering: 'optimizeLegibility',
								padding: '0 0 2px',
								fontFamily: "'Bembo'",
								fontSize: '1.07143rem',
								lineHeight: '1.2',
								margin: '0',
								textTransform: 'none',
								letterSpacing: 'normal',
								fontStyle: 'normal',
							}}
						>
							{!props.hidePrice &&
								getPriceComponent(
									'1.1rem',
									props.product?.price || 0,
									props.product?.priceAfterDiscount
								)}
						</Typography>
					</Box>
				</Box>
			</Link>
		</Box>
	)
}
